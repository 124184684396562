export const GET_CRM_ENQUIRY = "GET_CRM_ENQUIRY";
export const GET_CRM_ENQUIRY_SOURCE = "GET_CRM_ENQUIRY_SOURCE";
export const GET_CUSTOMER_BUDGET = "GET_CUSTOMER_BUDGET";
export const GET_CRM_DIMENSION = "GET_CRM_DIMENSION";
export const GET_CUSTOMER_CONSTRUCTIONS_PLANNING =
  "GET_CUSTOMER_CONSTRUCTIONS_PLANNING";
export const GET_CUSTOMER_PURCHASE_REASON = "GET_CUSTOMER_PURCHASE_REASON";
export const GET_CRM_SITE_SOLD = "GET_CRM_SITE_SOLD";
export const GET_CRM_CUSTOMER_ENQUIRY = "GET_CRM_CUSTOMER_ENQUIRY";
export const GET_CRM_ENQUIRY_PROFILE = "GET_CRM_ENQUIRY_PROFILE";
export const GET_CRM_ENQUIRY_PROFILE_EXPORT = "GET_CRM_ENQUIRY_PROFILE_EXPORT";
export const GET_SITE_VISIT_FEEDBACK_QUESTION =
  "GET_SITE_VISIT_FEEDBACK_QUESTION";
export const GET_SITE_VISIT_FEEDBACK = "GET_SITE_VISIT_FEEDBACK";
export const GET_SITE_VISIT_FEEDBACK_EXPORT = "GET_SITE_VISIT_FEEDBACK_EXPORT";
export const GET_SITE_VISIT_FEEDBACK_DETAIL = "GET_SITE_VISIT_FEEDBACK_DETAIL";
export const GET_CRM_BOOKING_ENQUIRY_PROFILE =
  "GET_CRM_BOOKING_ENQUIRY_PROFILE";
export const GET_CRM_MEETING = "GET_CRM_MEETING";
export const GET_CRM_MEETING_EXPORT = "GET_CRM_MEETING_EXPORT";
export const GET_CRM_MEETING_CATEGORY = "GET_CRM_MEETING_CATEGORY";
export const GET_CRM_MEETING_ATTENDEE = "GET_CRM_MEETING_ATTENDEE";
export const GET_CRM_MEETING_DETAIL = "GET_CRM_MEETING_DETAIL";
export const GET_CRM_MEETING_SOURCE = "GET_CRM_MEETING_SOURCE";
export const GET_CRM_MEETING_CHANNEL_PARTNER_INFO =
  "GET_CRM_MEETING_CHANNEL_PARTNER_INFO";
export const GET_CRM_MEETING_CHANNEL_CLIENT_INFO =
  "GET_CRM_MEETING_CHANNEL_CLIENT_INFO";
export const GET_CRM_MEETING_PREFERRED_LOCATION =
  "GET_CRM_MEETING_PREFERRED_LOCATION";
export const GET_CRM_MEETING_PREFERRED_LOCATION_EXPORT =
  "GET_CRM_MEETING_PREFERRED_LOCATION_EXPORT";
export const GET_CRM_MEETING_STM_INFO = "GET_CRM_MEETING_STM_INFO";
export const GET_CRM_SOURCE_GROUP = "GET_CRM_SOURCE_GROUP";
export const GET_CRM_SOURCE_GROUP_EXPORT = "GET_CRM_SOURCE_GROUP_EXPORT";
export const GET_CRM_SOURCE_CATEGORY = "GET_CRM_SOURCE_CATEGORY";
export const GET_CRM_SOURCE = "GET_CRM_SOURCE";
export const GET_CRM_ENQUIRY_STATUS = "GET_CRM_ENQUIRY_STATUS";
export const GET_CRM_ENQUIRY_CATEGORY = "GET_CRM_ENQUIRY_CATEGORY";
export const GET_CRM_ENQUIRY_CALL_STATUS = "GET_CRM_ENQUIRY_CALL_STATUS";
export const GET_DIMENSION_MASTER = "GET_DIMENSION_MASTER";
export const GET_RELEASE_STATUS = "GET_RELEASE_STATUS";
export const GET_CRM_SITE_PREMIUM_TYPE = "GET_CRM_SITE_PREMIUM_TYPE";
export const GET_CRM_SITE_STATUS = "GET_CRM_SITE_STATUS";
export const GET_SITE_INVESTOR = "GET_SITE_INVESTOR";
export const GET_BLOCK_REASON = "GET_BLOCK_REASON";
export const GET_ENQUIRY_FOLLOW_STATUS = "GET_ENQUIRY_FOLLOW_STATUS";

export const GET_CRM_CHANNEL_PARTNER = "GET_CRM_CHANNEL_PARTNER";
export const GET_CRM_CLIENT = "GET_CRM_CLIENT";
export const GET_CRM_STM = "GET_CRM_STM";
export const GET_CRM_MEETING_FILTER_PROJECT = "GET_CRM_MEETING_FILTER_PROJECT";
export const GET_CRM_MEETING_FILTER_HOSTED_BY =
  "GET_CRM_MEETING_FILTER_HOSTED_BY";
export const GET_FELLOW_UP = "GET_FELLOW_UP";
export const GET_FELLOW_UP_EXPORT = "GET_FELLOW_UP_EXPORT";
export const GET_CRM_FOLLOW_UP_FILTER_ASSIGNED_TO =
  "GET_CRM_FOLLOW_UP_FILTER_ASSIGNED_TO";
