import {
  ErpApi,
  ErpApiService,
  FileUploadService,
  fileDownloadService,
  ApiPaths,
  ApiService
} from "../../utils/ApiService";
import {
  GET_PROJECT_AUTHORITY,
  GET_BD_USER,
  GET_PROJECT_PHASE,
  GET_AUTHORIZATION,
  GET_MENU_PERMISSION,
  GET_DISTRICT,
  GET_TALUK,
  GET_HOBLI,
  GET_PROJECT_BROKERS,
  GET_UPCOMING_PROJECT_LIST,
  GET_FUNCTIONALITY,
  GET_VILLAGE_LIST,
  GET_PROJECT_OWNERS_NAMES,
  GET_PROJECT_NAMES,
  GET_MY_WEB_NOTIFICATION,
  GET_PROJECT_LIST,
  GET_PROJECT_FLOW_LIST,
  GET_PROJECT_TASK_STATUS,
  GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
  GET_PROJECT_TASK_PLAN_DETAILS,
  GET_PROJECT_PLAN,
  GET_PROJECT_FILE_LIST,
  GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
  GET_LAND_OWNER_NAMES,
  GET_LIASON_PROCESS_PLAN,
  GET_LIASON_TASK_PLAN,
  GET_LIASON_DOCUMENT_TASK_PLAN,
  GET_PROJECT_DELAY_REASON,
  GET_DOCUMENT_TYPES,
  GET_PROJECT_BD_OWNER,
  GET_PROJECT_LEGAL_OWNER,
  GET_PROJECT_PLAN_FILE_LIST,
  GET_LIAISON_CATEGORY_LIST,
  GET_LIAISON_PROCESS_LIST,
  GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
  GET_LAND_OWNER_LIST,
  GET_SURVEY_NO_LIST,
  GET_SURVEY_DETAIL_LIST,
  GET_FILE_ACQUISITION_INFO_LIST,
  GET_ACQUISITION_MODE_LIST,
  GET_ACQUISITION_INFO_DETAIL,
  GET_LIAISON_DOCUMENT_CATEGORY_LIST,
  GET_PROJECT_LIST_WITH_EXTENT,
  GET_FEASIBILITY_ITEM_LIST,
  GET_FEASIBILITY_REPORT_LIST,
  GET_FEASIBILITY_REPORT_DETAIL,
  GET_LAND_OWNER_DETAIL,
  GET_LAND_BROKER_LIST,
  GET_LAND_BROKER_DETAIL,
  GET_LAND_OWNNER_VILLAGE_LIST,
  GET_PROJECT_FUND_SOURCE_LIST,
  GET_PROJECT_FUND_REQUIREMENT_DETAIL,
  GET_PROJECT_FUND_LIST,
  GET_PROJECT_FUND_DETAIL,
  GET_PROJECT_FILE_PAYEMENT_DETAIL,
  GET_BD_PAYMENT_LIST,
  GET_BD_PAYMENT_DETAIL,
  GET_BD_PAYMENT_MILESTONE,
  GET_PROJECT_BROKER_LIST,
  GET_PROJECT_BROKER_DETAIL,
  GET_PROJECT_BROKER_PAYMENT_INFO,
  GET_PROJECT_LAND_OWNER,
  GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,
  GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILE,
  GET_LIASON_PROCESS_PLAN_APPLICATION,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
  GET_PROJECTC_MILESTONE,
  GET_BD_REGISTRATION_ITEM,
  GET_SUBREGISTER_OFFICE,
  GET_PROJECT_FILE_DOCUMENT,
  GET_DEPENDENT_RELATIONSHIP,
  GET_BD_PAYMENT_ADVANCE_DETAIL,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DROP_REASON_LIST,
  GET_COMPANY_PROJECT_MAP,
  GET_COMPANY,
  GET_DROPPED_PROJECT_LIST,
  GET_INSERTED_LAND_OWNER_DATA,
  GET_INSERTED_LAND_BROKER_DATA,
  GET_LAND_OWNER_ACCOUNT_DETAIL,
  GET_LAND_BROKER_ACCOUNT_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
  GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
  GET_USER_PROFILE,
  GET_BIRTHDAY,
  GET_EMPLOYEE_WORK_ANNIVERSARY,
  GET_PROJECT_LIST_FILTER,
  GET_PROJECT_UPCOMING_FILTER,
  GET_CIVIL_PROJECT_FILTER,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
  GET_PROJECT_FILE_FILTER,
  GET_LIAISON_PROCESS_FILTER,
  GET_APF_PANEL,
  GET_APF_PENDING_PROJECT_FILE,
  GET_APF_TASK_PLAN,
  GET_APF_TASK_PLAN_DETAIL,
  GET_APF_TASK_PLAN_PROJECT_FILTER,
  GET_APF_TASK_PLAN_PANEL_FILTER,
  GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,
  GET_APF_TASK_FILTER,
  GET_APF_TASK_PLAN_QUERY,
  GET_APF_TASK_PLAN_QUERY_DETAIL,
  GET_LIAISON_DOCUMENTATION_REPORT,
  GET_BD_LAND_DISPUTE_TYPE,
  GET_BD_LAND_DISPUTE_LIST,
  GET_BD_LAND_DISPUTE_DETAIL,
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
  GET_COURT_CASE_TYPE,
  GET_COURT_CASE_STATUS,
  GET_COURT_CASE_JURISDICTION,
  GET_DIRECTOR,
  GET_COURT_CASE,
  GET_COURT_CASE_DETAIL,
  GET_TRAINING_CONTENT,
  GET_BD_PROJECT_TASK_REJECTION_REASON,
  GET_BD_AGREEMENT_TYPE,
  GET_BD_PROJECT_REPORT,
  GET_BD_PROJECT_TASK_REPORT,
  GET_PROJECT_PROPOSED_NAME,
  GET_TRAINING,
  GET_APF_TASK_PLAN_TASK_FILTER,
  GET_LIAISON_PROCESS_PLAN_PROJECT,
  GET_LIAISON_PROCESS_PLAN_PROCESS,
  GET_LIAISON_PAYMENT_LIST,
  GET_LIAISON_PAYMENT_DETAIL,
  GET_LIAISON_PAYMENT_SURVEY_NUMBER,
  GET_LIAISON_PAYMENT_TASK_PLAN,
  GET_FUNCTIONALITY_PERMISSION_DETAIL,
  GET_LIAISON_PAYMENT_FILTER_PROJECT,
  GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,
  GET_PROJECT_NAMES1,
  GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
  GET_PROJECT_TASK_PLAN_FILTER_TASK,
  GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
  GET_LIAISON_APPLICABLE_PROCESS_PLAN,
  GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
  GET_DEPARTMENT,
  GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,
  GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL,
  GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1,
  GET_LIAISON_TASK_PLAN_DETAIL,
  GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL,
  GET_LIAISON_TASK,
  GET_LIAISON_PROCESS_PLAN_PENDING_TASK,
  GET_LIAISON_PROCESS,
  GET_LIAISON_PROCESS_CATEGORY1,
  GET_LIAISON_PROCESS_OPTIONAL,
  GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL,
  GET_LIAISON_PROCESS_PLAN,
  GET_LIAISON_PROCESS_PLAN_APPROVAL,
  GET_LIAISON_PROCESS_PLAN_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT,
  GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS,
  GET_BD_PROJECT_TASK_REPORT_EXPORT,
  GET_NEWS_LETTER,
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE,
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY,
  GET_LIAISON_PROCESS_PLAN_EXPORT,
  GET_PROJECT_ZONE,
  GET_COURT_CASE_HANDLED_BY,
  GET_BD_LAND_DISPUTE_HANDLED_BY,
  GET_BD_LEGAL_TASK_LIST_EXPORT,
  GET_BD_LAND_DISPUTE_EXPORT,
  GET_COURT_CASE_EXPORT,
  GET_COURT_CASE_PROJECT_FILTER,
  GET_PROJECT_DEED,
  GET_BD_PROJECT_TASK,
  GET_BD_PROJECT_CONSOLIDATED_REPORT,
  GET_PROJECT_FILE_ACQUISITION_PENDING,
  GET_ACQUIRED_FILE_EXPORT,
  GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER,
  GET_FILE_ACQUISITION_INFO_EXPORT,
  GET_PROJECT_FILE_EXPORT,
  GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT,
  GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL,
  GET_BD_FILE_ACQUISITION_REASON,
  GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
  GET_PROJECT_LEGAL_OWNER_FILTER,
  GET_PROJECT_BD_OWNER_FILTER,
  GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON,
  GET_LOAN_PANEL,
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT,
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE,
  GET_LOAN_PROCESS_PLAN,
  GET_LOAN_TASK_PLAN,
  GET_LOAN_TASK_PLAN_DETAIL,
  GET_PROJECT_LIAISON_OWNER_FILTER,
  GET_LOAN_PROCESS_PLAN_DETAIL,
  GET_LOAN_PROCESS_PLAN_PROJECT_FILTER,
  GET_LOAN_PROCESS_PLAN_PROCESS_FILTER,
  GET_LOAN_PROCESS_PLAN_BANK_FILTER,
  GET_LOAN_PROCESS_PLAN_OWNER_FILTER,
  GET_LIAISON_PROCESS_PLAN_DELAYED,
  GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT,
  // BD Master
  GET_BD_LAND_DISPUTE_TYPE_MASTER,
  PROJECT_DEPENDENT_TASK_MASTER,
  GET_PLANNING_AUTHORITY_MASTER,
  GET_LIAISON_PROCESS_CATEGORY_MASTER,
  GET_BD_PROJECT_TASK_CATEGORY_MASTER,
  PROJECT_TASK_DEPENDENCY_MASTER,
  GET_LIAISON_CATEGORY_MASTER,
  GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT,
  GET_LIAISON_PROCESS_MASTER,
  GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW,
  GET_LIAISON_PROCESS_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST,
  GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER,
  GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT,
  GET_BD_PAYMENT_MILESTONE_MASTER,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT,
  GET_PROJECT_MOU_REPORT_KPI,
  GET_PROJECT_MOU_REPORT_KPI_EXPORT,
  GET_PROJECT_TASK_REJECTION_REASON_MASTER,
  GET_AGREEMENT_TYPE_MASTER,
  GET_PROJECT_MOU_KPI_REPORT_FILTER_PROJECT,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT,
  GET_COURT_ESTABLISHMENT,
  GET_COURT_CASE_STATUS_MASTER,
  GET_COURT_CASE_TYPE_MASTER,
  GET_REGISTRATION_FEES_RATE_LIST,
  GET_REGISTRATION_FEES_MASTER,
  GET_LIAISON_PROCESS_DEPENDENCY,
  GET_APF_TASK_LIST,
  GET_APF_PANEL_LIST,
  GET_LIASION_PROCESSES_EXCEPTION,
  GET_MASTER,
  GET_PROJECT_DOCUMENT_TYPE,
  GET_LIASISON_DELAY_REASON,
  GET_BANK,
  GET_BANK_CATEGORY,
  GET_LOAN_TASK,
  GET_LOAN_PROCESS,
  GET_LIAISON_PAYMENT_EXPORT,
  GET_PROJECT_TASK_TRANSACTION,
  GET_BD_TASK_APPROVAL,
  GET_PROJECT_EXPORT,
  GET_SUB_REGISTRAR_OFFICE,
  GET_SUB_REGISTRAR_MAPPING,
  GET_REGISTRATION_FEES_RATE_MASTER,
  GET_LIAISON_PROCESS_APPLICABILITY_REASON,
  GET_LANDLORD_MEETING,
  GET_LANDLORD_MEETING_STAGE,
  GET_LANDLORD_MEETING_PARTICULAR,
  GET_NEWS_LETTER_CATEGORY,
  GET_APF_TASK_PLAN_REPORT,
  GET_PROJECT_DOCUMENT_TYPE_EXPORT,
  GET_BANK_MASTER_EXPORT,
  GET_APF_PANEL_EXPORT,
  GET_LOAN_PROCESS_EXPORT,
  GET_LOAN_PANEL_EXPORT,
  GET_LIAISON_TASK_EXPORT,
  GET_LIAISON_PROCESS_MASTER_EXPORT,
  GET_BD_PROJECT_TASK_MASTER_EXPORT,
  GET_LIASISON_DELAY_REASON_MASTER_EXPORT,
  GET_REGISTRATION_FEES_RATE_EXPORT_MASTER,
  GET_AGREEMENT_TYPE_EXPORT_MASTER,
  GET_LIASION_PROCESSES_EXCEPTION_EXPORT,
  GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER,
  GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER,
  GET_REGISTRATION_FEES_EXPORT_MASTER,
  GET_PLANNING_AUTHORITY_EXPORT_MASTER,
  PROJECT_TASK_DEPENDENCY_EXPORT_MASTER,
  GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER,
  GET_BD_TASK_EXPORT_APPROVAL,
  GET_PROJECT_DROP_REASON_EXPORT_LIST,
  GET_PROJECT_TASK_TRANSACTION_EXPORT,
  GET_SUB_REGISTRAR_MAPPING_EXPORT,
  GET_LOAN_TASK_EXPORT,
  GET_LIAISON_CATEGORY_EXPORT,
  GET_COURT_CASE_TYPE_EXPORT,
  GET_COURT_CASE_STATUS_EXPORT,
  GET_COURT_ESTABLISHMENT_EXPORT,
  GET_BD_LAND_DISPUTE_TYPE_EXPORT,
  GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT,
  GET_LIAISON_PROCESS_DEPENDENCY_EXPORT,
  GET_LIAISON_PROCESS_CATEGORY_EXPORT,
  GET_APF_TASK_LIST_EXPORT,
  GET_LAND_LORD_MEETING_PARTICULAR_REMARK,
  GET_LAND_LORD_MEETING_ATTENDEE,
  GET_LAND_LORD_MEETING_PARTICULAR_EXPORT,
  GET_LAND_LORD_MEETING_PARTICULAR,
  GET_LAND_LORD_MEETING_STAGE_MASTER,
  GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
  GET_LANDLORD_MEETING_DETAIL,
  GET_APF_TASK_PLAN_REPORT_EXPORT,
  GET_APF_TASK_PLAN_EXPORT,
  GET_APF_TASK_PLAN_QUERY_EXPORT,
  GET_LAND_LORD_MEETING_MOM,
  GET_LIAISON_PROCESS_DEPENDENT,
  GET_BD_PROJECT_DEED_TYPE,
  GET_LIAISON_PROCESS_PLAN_MERGER_FILE,
  GET_BD_LAND_DISPUTE_PRIORITY,
  GET_APF_TASK_PLAN_QUERY_TEMPLETE,
  GET_LIAISON_PROCESS_PLAN_EXCEPTION,
  GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY,
  GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER,
  GET_APF_TASK_PLAN_FILE,
  GET_LANDLORD_MEETING_DETAIL_PRINT,
  GET_APF_TASK_PLAN_QUERY_FILTER_FILE,
  GET_LIAISON_PROCESS_AUTHORITY_MAP,
  GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE,
  GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE,
  GET_APF_TASK_PLAN_OWNER_FILTER,
  GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
  GET_PROJECT_INSERTED_DATA,
  GET_PROJECT_ZONE_EXPORT,
  GET_LAND_LORD_MEETING_EXPORT,
  GET_LIAISON_PROCESS_FOR_EXCEPTION,
  GET_LANDLORD_MEETING_REPORT,
  GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT,
  GET_PROJECT_FILE_FILTER_FOR_TEMPLATE,
  GET_PROJECT_CATEGORY,
  GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP,
  GET_PROJECT_CATEGORY_EXPORT,
  GET_PROJECT_FILE_FILTER_PROJECT,
  GET_PROJECT_PRIORITY,
  GET_LAND_ACQUISITION_KPI_REPORT,
  GET_LAND_ACQUISITION_KPI_REPORT_EXPORT,
  GET_PROJECT_MOU_KPI_REPORT,
  GET_PROJECT_MOU_KPI_REPORT_EXPORT,
  GET_LAND_ACQUISITION_REPORT,
  GET_LAND_ACQUISITION_REPORT_EXPORT,
  GET_APF_TASK_PLAN_QUERY_REPORT,
  GET_APF_TASK_PLAN_QUERY_REPORT_EXPORT,
  GET_PROJECT_PHASE_MASTER,
  GET_PROJECT_PHASE_MASTER_EXPORT,
  GET_LAND_ACQUISITION_REPORT_ITEM,
  GET_LAND_ACQUISITION_REPORT_ITEM_EXPORT,
  GET_PO_REJECTION_REPORT,
  GET_PO_CYCLE_REPORT,
  GET_STOCK_PROJECT,
  GET_LAND_ACQUISITION_OVERALL_REPORT,
  GET_BD_TASK_APPROVAL_FILTER_USER,
  GET_BD_TASK_APPROVAL_FILTER_TASK,
  GET_BD_TASK_APPROVAL_TASK_TO_ADD,
  GET_PROJECT_TASK_FOR_OWNER_UPDATE,
  GET_PROJECT_DROPPED_FILTER,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE,
  GET_PROJECT_FLOW_LIST_EXPORT,
  GET_APF_TASK_EXTRA_FILE,
  GET_SUB_MENU,
  GET_PROJECT_DOCUMENT_REPOSITORY,
  GET_PROJECT_DOCUMENT_REPOSITORY_EXPORT,
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER,
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER,
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE,
  PROJECT_FILE_MOVE,
  TO_PROJECT_MOVE_FILE,
  GET_PROJECT_SUB_TASK_EXPORT,
  GET_PROJECT_SUB_TASK,
  GET_LIAISON_PROCESS_PLAN_KPI_EXPORT,
  GET_LIAISON_PROCESS_PLAN_KPI,
  GET_LIAISON_PROCESS_PLAN_EXPORT_DATA,
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT,
  GET_REGISTRATION_PAYMENT,
  GET_REGISTRATION_PAYMENT_SURVEY_INFO,
  GET_BD_REGISTRATION_PAYMENT_DETAIL,
  GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_LAND,
  GET_COMPANY_PROJECT_MAP_SITE_REGISTRATION,
  GET_BD_REGISTRATION_PAYMENT_SITE_INFO,
  GET_MODE_CATEGORY_SITE_REGISTRATION,
  GET_MODE_SITE_REGISTRATION,
  UPDATE_BD_REGISTRATION_PAYMENT_SETTLEMENT_SETTLE,
  GET_BD_REGISTRATION_PAYMENT_FILTER_COMPANY,
  GET_PROJECT_BD_KPI_REPORT_EXPORT,
  GET_PROJECT_BD_KPI_REPORT,
  GET_PROJECT_BD_KPI_REPORT_FILTER_PROJECT,
  GET_SITE_REGISTRATION_PROJECTS,
  GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_SITE,
  GET_BD_FEES_ITEM,
  GET_PROCESS_FEE_ITEM,
  GET_BD_REGISTRATION_PAYMENT_PROCESS,
  GET_BD_REGISTRATION_PAYMENT_DEED_TYPE,
  GET_PROJECT_TASK_PLAN_PENDING,
  GET_PENDING_LIAISON_PROCESS_PLAN,
  GET_BD_FEES_ITEM_AVAILABLE,
  GET_DROPPED_PROJECT_LIST_EXPORT,
  GET_BD_LAND_DISPUTE_FILTER_ACCOUNTABLE,
  GET_COURT_CASE_FILTER_ACCOUNTABLE,
  GET_COMPANY_PROJECT_MAP_EXPORT,
  COMPANY_PROJECT_MAP_LIST
} from "./types";
import {
  mainLoadingTrue,
  mainLoadingFalse,
  displayMessage
} from "../User/action";
import { getItem } from "../../utils/crypto";
const userLocal = getItem("enuseloc");
const LoginuserId =
  userLocal && Object.keys(userLocal).length > 0 ? userLocal["user_id"] : null;
export const getProjectAuthority_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorityID: "",
    authorityStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_AUTHORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectAuthoritySuccess(data.projectAuthority));
        } else {
          dispatch(getProjectAuthoritySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectAuthoritySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectAuthoritySuccess = (data) => {
  return {
    type: GET_PROJECT_AUTHORITY,
    payload: data
  };
};
export const getBdUser_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    departmentID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.BD_USER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdSuccess(data.user));
        } else {
          dispatch(getBdSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdSuccess = (data) => {
  return {
    type: GET_BD_USER,
    payload: data
  };
};
export const getProjectPhase_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_PHASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectPhaseSuccess(data.data));
        } else {
          dispatch(getProjectPhaseSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectPhaseSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPhaseSuccess = (data) => {
  return {
    type: GET_PROJECT_PHASE,
    payload: data
  };
};
export const getDistrict_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DISTRICTS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDistrictSuccess(data.data));
        } else {
          dispatch(getDistrictSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDistrictSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDistrictSuccess = (data) => {
  return {
    type: GET_DISTRICT,
    payload: data
  };
};
export const getTaluk_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    districtID: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.TALUKS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getTalukSuccess(data.data));
        } else {
          dispatch(getTalukSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getTalukSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getTalukSuccess = (data) => {
  return {
    type: GET_TALUK,
    payload: data
  };
};
export const getHobli_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    talukID: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.HOBLI, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getHobliSuccess(data.data));
        } else {
          dispatch(getHobliSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getHobliSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getHobliSuccess = (data) => {
  return {
    type: GET_HOBLI,
    payload: data
  };
};
export const getProjectBrokers_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_BROKERS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBrokersSuccess(data.data));
        } else {
          dispatch(getProjectBrokersSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBrokersSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBrokersSuccess = (data) => {
  return {
    type: GET_PROJECT_BROKERS,
    payload: data
  };
};
export const postProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.POST_PROJECT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
          dispatch(getProjectInsertedData(data.data));
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectInsertedData = (data) => {
  return {
    type: GET_PROJECT_INSERTED_DATA,
    payload: data
  };
};
export const getProjectList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    priorityID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectListSuccess(data));
        } else {
          dispatch(getProjectListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectListSuccess = (data) => {
  return {
    type: GET_PROJECT_LIST,
    payload: data
  };
};
export const getUpgoingProjectList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPCOMING_PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_UPCOMING_PROJECT_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: GET_UPCOMING_PROJECT_LIST,
            payload: data
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_UPCOMING_PROJECT_LIST,
          payload: data
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVillageList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    hobliID: "",
    taskID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.VILLAGE_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getVillageListSuccess(data.data));
        } else {
          dispatch(getVillageListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVillageListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVillageListSuccess = (data) => {
  return {
    type: GET_VILLAGE_LIST,
    payload: data
  };
};
export const getProjectFlowList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    applicableStatus: "",
    parentID: "0",
    ownershipID: "",
    generalSearch: "",
    sortOrder: "",
    sortParameter: "",
    ...data
    // taskOwnerID : LoginuserId
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFlowListSuccess(data));
        } else {
          dispatch(getProjectFlowListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFlowListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFlowListSuccess = (data) => {
  return {
    type: GET_PROJECT_FLOW_LIST,
    payload: data
  };
};
export const getProjectTaskPlanFilterOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_OWNERS_NAMES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_OWNERS_NAMES,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_OWNERS_NAMES,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_OWNERS_NAMES,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    projectID: "",
    planningAuthorityID: "",
    projectStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_NAMES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_NAMES,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_NAMES,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_NAMES,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanFilterProject1_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    taskOwnerID: "",
    taskPlanStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_NAMES1, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_NAMES1,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_NAMES1,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_NAMES1,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_STATUS,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_STATUS,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_STATUS,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectTaskRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //"taskType":"ProjectTask",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_TASK_REMARK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDelayReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonTypeID: "",
    taskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_DELAY_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_DELAY_REASON,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_DELAY_REASON,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_DELAY_REASON,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDocumentType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskTypeID: "",
    taskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_DOCUMENT_TYPES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_DOCUMENT_TYPES,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_DOCUMENT_TYPES,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_DOCUMENT_TYPES,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadProjectTaskDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentType", data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("documentFile", data.documentFile);
  initialPayload.append(
    "taskType",
    data?.taskTypeID ? data?.taskTypeID : "ProjectTask"
  );
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_PROJECT_UPLOAD_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectTaskComplete_action = (data) => {
  let initialPayload = new FormData();
  // initialPayload.append('documentType',  data.documentType);
  initialPayload.append("taskCompletedDate", data.taskCompletedDate);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("documentFile", data.documentFile);
  initialPayload.append("userID", LoginuserId);
  initialPayload.append(
    "taskType",
    data?.taskType ? data?.taskType : "ProjectTask"
  );
  initialPayload.append("documentRemark", "");
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_PROJECT_TASK_COMPLETE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// export const updateProjectSubTaskComplete_action = (data) => {
//     let initialPayload = new FormData();
//     // initialPayload.append('documentType',  37);
//     initialPayload.append('userID', LoginuserId);
//     for(var i = 0 ; i < data.taskDocuments.length; i++) {
//         initialPayload.append('documentFile'+data.taskDocuments[i].documentType, data.taskDocuments[i].documentFile);
//     }
//     initialPayload.append('taskDocuments', JSON.stringify(data.taskDocuments));
//     initialPayload.append('taskCompletedDate', data.taskCompletedDate);
//     initialPayload.append('taskPlanID', data.taskPlanID);
//     initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
//     initialPayload.append('documentRemark', "");
//     // initialPayload.append('documentFile', data.documentFile);
//     initialPayload.append('taskType', data.taskType);
//     console.log("data", initialPayload);
//     const config = {
//         headers: { 'content-type': 'multipart/form-data' }
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return FileUploadService.post(ErpApi.UPDATE_PROJECT_SUB_TASK_COMPLETE, initialPayload, config).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
export const getProjectTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ownershipID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_PLAN_DETAILS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_DETAILS,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_DETAILS,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_PLAN_DETAILS,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    parentID: "0",
    generalSearch: "",
    sortOrder: "",
    sortParameter: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_PLAN,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_PLAN,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_PLAN,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadProjectTaskStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_TASK_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadProjectTaskApprovalStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_APROVAL_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createProjectFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.POST_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    fileStatus: "Active",
    projectStatus: "Active",
    acquisitionStatus: "",
    acquisitionReasonID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_FILE_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_FILE_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_FILE_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    villageID: "",
    parentID: "",
    ownerStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LAND_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_OWNER_NAMES,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LAND_OWNER_NAMES,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_OWNER_NAMES,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiasonProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.LIASON_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_PROCESS_PLAN,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_PROCESS_PLAN,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_PROCESS_PLAN,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    accountableID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.LIASON_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_TASK_PLAN,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_TASK_PLAN,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_TASK_PLAN,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateFirebaseKeyWeb_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.POST_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorityID: "",
    projecPhaseID: "",
    projecStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_BD_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_BD_OWNER,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_BD_OWNER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_BD_OWNER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLegalOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorityID: "",
    projecPhaseID: "",
    projecStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LEGAL_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_LEGAL_OWNER,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_PROJECT_LEGAL_OWNER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_LEGAL_OWNER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    accountableID: "",
    projectID: "",
    projectFileID: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    applicableStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.LIASON_DOCUMENT_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN,
            payload: { ...data, initialPayload: initialPayload }
          });
        } else {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_DOCUMENT_TASK_PLAN,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApproval_action = (data) => {
  const initialPayload = {
    fromDate: "",
    toDate: "",
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    taskApproverID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanApproval_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //"projectID":"61",
    taskID: "",
    taskOwnerID: "",
    taskStatus: "",
    taskApproverID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.PROJECT_SURVEY_NO_TASK_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanApprovalDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskPlanApprovalID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessApplicabilityStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    applicabilityStatus: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIASON_PROCESS_APPLICATION_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanComplete_action = (data) => {
  let initialPayload;
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("documentRemark", data?.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("taskCompletedDate", data.taskCompletedDate);
  initialPayload.append("isSequenceExecution", data.isSequenceExecution);
  const config = data?.documentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIASON_TASK_PLAN_COMPLETE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getMyWebNotification_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    notificationFor: "",
    notificationType: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 50
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.MY_WEB_NOTIFICATION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_MY_WEB_NOTIFICATION,
            payload: data
          });
        } else {
          dispatch({
            type: GET_MY_WEB_NOTIFICATION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_MY_WEB_NOTIFICATION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAuthorization_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    functionalityID: "",
    grantUser: "",
    status: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.AUTHORIZATION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_AUTHORIZATION,
            payload: data
          });
        } else {
          dispatch({
            type: GET_AUTHORIZATION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_AUTHORIZATION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const clearAuthorizationList_action = () => {
  return (dispatch) => {
    dispatch({
      type: GET_AUTHORIZATION,
      payload: []
    });
  };
};
export const getMenuForPermission_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    functionalityID: "",
    grantUser: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.MENU_PERMISSION, initialPayload)
      .then((response) => {
        const { data } = response;
        // console.log("menuForPermissionList", data.data)
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_MENU_PERMISSION,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_MENU_PERMISSION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_MENU_PERMISSION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFunctionality_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    functionalityID: "",
    status: "",
    grantUser: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.FUNCTIONALITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_FUNCTIONALITY,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_FUNCTIONALITY,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_FUNCTIONALITY,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateUserFunctionalityPermission_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    grantUser: LoginuserId,
    functionalityID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_USER_FUNCTIONALITY_PERMISSION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPlanFileList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_PLAN_FILE_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectPlanFileListSuccess(data));
        } else {
          dispatch(getProjectPlanFileListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectPlanFileListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPlanFileListSuccess = (data) => {
  return {
    type: GET_PROJECT_PLAN_FILE_LIST,
    payload: data
  };
};
export const getLiaisonCategoryList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.LIAISON_PROCESS_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonCategoryListSuccess(data));
        } else {
          dispatch(getLiaisonCategoryListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonCategoryListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonCategoryListSuccess = (data) => {
  return {
    type: GET_LIAISON_CATEGORY_LIST,
    payload: data
  };
};
export const getLiaisonProcessList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.FILE_LIAISON_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessListSuccess(data.data));
        } else {
          dispatch(getLiaisonProcessListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessListSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_LIST,
    payload: data
  };
};
export const updateLiaisonProcessPlanApplicability_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonID: "1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICABILITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
//New
export const getLandOwnerList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    parentID: "",
    ownerStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LAND_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandOwnerSuccess(data));
        } else {
          dispatch(getLandOwnerSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandOwnerSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerSuccess = (data) => {
  return {
    type: GET_LAND_OWNER_LIST,
    payload: data
  };
};
export const getSurveyNo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SURVEY_NO, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getSurveyNumberSuccess(data.data));
        } else {
          dispatch(getSurveyNumberSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getSurveyNumberSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSurveyNumberSuccess = (data) => {
  return {
    type: GET_SURVEY_NO_LIST,
    payload: data
  };
};
export const getSurveyNoDetails_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SURVEY_NO_DETAILS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getSurveyNumberDetailSuccess(data));
        } else {
          dispatch(getSurveyNumberDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getSurveyNumberDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSurveyNumberDetailSuccess = (data) => {
  return {
    type: GET_SURVEY_DETAIL_LIST,
    payload: data
  };
};
export const createFileAcquisitionInfo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_FILE_ACQUISITION_INFO,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFileAcquisitionInfo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    acquisitionInfoID: "",
    projectFileID: "",
    villageID: "",
    projectID: "",
    infoStatus: "",
    acquisitionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_INFO, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAcquisitionInfoListSuccess(data));
        } else {
          dispatch(getAcquisitionInfoListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAcquisitionInfoListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAcquisitionInfoListSuccess = (data) => {
  return {
    type: GET_FILE_ACQUISITION_INFO_LIST,
    payload: data
  };
};
export const getAcquisitionMode_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_MODE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAcquisitionModeSuccess(data.data));
        } else {
          dispatch(getAcquisitionModeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAcquisitionModeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAcquisitionModeSuccess = (data) => {
  return {
    type: GET_ACQUISITION_MODE_LIST,
    payload: data
  };
};
export const getAcquisitionInfoDetail_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_INFO_DETAIL, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAcquisitionInfoDetailSuccess(data));
        } else {
          dispatch(getAcquisitionInfoDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAcquisitionInfoDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAcquisitionInfoDetailSuccess = (data) => {
  return {
    type: GET_ACQUISITION_INFO_DETAIL,
    payload: data
  };
};
export const updateFileAcquisitionInfoStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_FILE_ACQUISITION_INFO_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectWithExtent_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    acquisitionInfoID: 2,
    projectFileID: "",
    villageID: "",
    projectID: "",
    infoStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_WITH_EXTENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectWithExtentSuccess(data));
        } else {
          dispatch(getProjectWithExtentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectWithExtentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectWithExtentSuccess = (data) => {
  return {
    type: GET_PROJECT_LIST_WITH_EXTENT,
    payload: data
  };
};
export const getProjectFeasibilityItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FEASIBILITY_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFeasibilityItemSuccess(data));
        } else {
          dispatch(getProjectFeasibilityItemSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFeasibilityItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFeasibilityItemSuccess = (data) => {
  return {
    type: GET_FEASIBILITY_ITEM_LIST,
    payload: data
  };
};
export const updateFeasibilityReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_FEASIBILITY_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeasibilityReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FEASIBILITY_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFeasibilityReportList_success(data));
        } else {
          dispatch(getFeasibilityReportList_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFeasibilityReportList_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeasibilityReportList_success = (data) => {
  return {
    type: GET_FEASIBILITY_REPORT_LIST,
    payload: data
  };
};
export const getFeasibilityReportDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    feasibilityID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_FEASIBILITY_REPORT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFeasibilityReportDetail_success(data));
        } else {
          dispatch(getFeasibilityReportDetail_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFeasibilityReportDetail_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeasibilityReportDetail_success = (data) => {
  return {
    type: GET_FEASIBILITY_REPORT_DETAIL,
    payload: data
  };
};
export const createLandOwner_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("parentID", data.parentID);
  initialPayload.append("ownerType", data.ownerType);
  initialPayload.append("parentRelationship", data.parentRelationship);
  initialPayload.append("ownerName", data.ownerName);
  initialPayload.append("mobileNumber", data.mobileNumber);
  initialPayload.append("projectID", data.projectID);
  initialPayload.append("isPanPresent", data.isPanPresent);
  initialPayload.append("isAadharPresent", data.isAadharPresent);
  initialPayload.append("aadharNumber", data.aadharNumber);
  initialPayload.append("panNumber", data.panNumber);
  initialPayload.append("ownerRemarks", data.ownerRemarks);
  initialPayload.append("panDocumentFile", data?.panDocumentFile);
  initialPayload.append("aadharDocumentFile", data?.aadharDocumentFile);
  initialPayload.append("projectFileID", JSON.stringify(data?.projectFileID));
  const config = data?.panDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LAND_OWNER, initialPayload, config)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getInsertedLandOwnerId(data.data));
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getInsertedLandOwnerId = (data) => {
  return {
    type: GET_INSERTED_LAND_OWNER_DATA,
    payload: data
  };
};
export const createLandOwnerAccount_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("landOwnerID", data.landOwnerID);
  initialPayload.append("accountNumber", data.accountNumber);
  initialPayload.append("accountName", data.accountName);
  initialPayload.append("accountBank", data.accountBank);
  initialPayload.append("accountBranch", data.accountBranch);
  initialPayload.append("accountIfscCode", data.accountIfscCode);
  initialPayload.append("passBookDocumentFile", data?.passBookDocumentFile);
  const config = data?.passBookDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LAND_OWNER_ACCOUNT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_OWNER_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandOwnerDetail_success(data));
        } else {
          dispatch(getLandOwnerDetail_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandOwnerDetail_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerDetail_success = (data) => {
  return {
    type: GET_LAND_OWNER_DETAIL,
    payload: data
  };
};
export const getProjectBdLandBroker_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    brokerStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_BROKER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdLandBroker_success(data));
        } else {
          dispatch(getProjectBdLandBroker_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdLandBroker_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdLandBroker_success = (data) => {
  return {
    type: GET_LAND_BROKER_LIST,
    payload: data
  };
};
export const createLandBroker_action = (data) => {
  let initialPayload;
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("brokerName", data.brokerName);
  initialPayload.append("mobileNumber", data.mobileNumber);
  initialPayload.append("isPanPresent", data.isPanPresent);
  initialPayload.append("isAadharPresent", data.isAadharPresent);
  initialPayload.append("aadharNumber", data.aadharNumber);
  initialPayload.append("panNumber", data.panNumber);
  initialPayload.append("brokerRemarks", data.brokerRemarks);
  initialPayload.append("panDocumentFile", data?.panDocumentFile);
  initialPayload.append("aadharDocumentFile", data?.aadharDocumentFile);
  const config = data?.panDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LAND_BROKER, initialPayload, config)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getInsertedLandBrokerId(data.data));
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getInsertedLandBrokerId = (data) => {
  return {
    type: GET_INSERTED_LAND_BROKER_DATA,
    payload: data
  };
};
export const getProjectBdLandBrokerDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landBrokerID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_BROKER_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdLandBrokerDetail_success(data));
        } else {
          dispatch(getProjectBdLandBrokerDetail_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdLandBrokerDetail_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdLandBrokerDetail_success = (data) => {
  return {
    type: GET_LAND_BROKER_DETAIL,
    payload: data
  };
};
export const createLandBrokerAccount_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("landBrokerID", data.landBrokerID);
  initialPayload.append("accountNumber", data.accountNumber);
  initialPayload.append("accountName", data.accountName);
  initialPayload.append("accountBank", data.accountBank);
  initialPayload.append("accountBranch", data.accountBranch);
  initialPayload.append("accountIfscCode", data.accountIfscCode);
  initialPayload.append("passBookDocumentFile", data?.passBookDocumentFile);
  const config = data?.passBookDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LAND_BROKER_ACCOUNT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanMandotoryDocumentList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskPlanMandotoryDocumentSuccess(data));
        } else {
          dispatch(getProjectTaskPlanMandotoryDocumentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskPlanMandotoryDocumentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanMandotoryDocumentSuccess = (data) => {
  return {
    type: GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
    payload: data
  };
};
export const uploadProjectTaskPlanMandotoryDocument_action = (data) => {
  let initialPayload = new FormData();
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("userID", LoginuserId);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const feasibilityReportSendForApproval_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.FEASIBILITY_REPORT_SEND_FOR_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const approveFeasibilityReportTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanApprovalID: "",
    feasibilityReportID: "",
    statusID: "",
    remark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.APPROVE_FEASIBILITY_REPORT_TASK_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerVillageList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    villageID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_OWNER_VILLAGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandOwnerVillageListSuccess(data.data));
        } else {
          dispatch(getLandOwnerVillageListSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandOwnerVillageListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerVillageListSuccess = (data) => {
  return {
    type: GET_LAND_OWNNER_VILLAGE_LIST,
    payload: data
  };
};
export const getLiaisonDocumentCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENT_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDocumentCategorySuccess(data));
        } else {
          dispatch(getLiaisonDocumentCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDocumentCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentCategorySuccess = (data) => {
  return {
    type: GET_LIAISON_DOCUMENT_CATEGORY_LIST,
    payload: data
  };
};
export const updateLiaisonDocumentCategory_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonTaskID: "",
    categoryID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_DOCUMENT_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonTaskPlanID: "",
    ownerID: "",
    ownerType: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskStatusID: "",
    updateRemark: "",
    rejectionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_PROJECT_TASK_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ownerID: "",
    changeRemark: "",
    ownerType: "Responsible",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// export const createLiaisonDocumentCheckListPlan_action = (data) => {
//     let initialPayload = {
//         "userID": LoginuserId,
//         "projectID" : "",
//         "projectFileID" : "",
//         "processID" : "",
//         "documentName" : "",
//         "documentCategory" : "",
//         "documentType" : "",
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.CREATE_LIAISON_DOC_CHECKLIST_PLAN, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
export const createLiaisonDocumentCheckListPlan_action = (data) => {
  let initialPayload = new FormData();
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("projectID", data.projectID);
  initialPayload.append("projectFileID", data.projectFileID);
  initialPayload.append("processID", data.processID);
  initialPayload.append("documentName", data?.documentName);
  initialPayload.append("documentCategory", data.documentCategory);
  initialPayload.append("documentType", data?.documentType);
  initialPayload.append("documentRemark", data?.documentRemark);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.CREATE_LIAISON_DOC_CHECKLIST_PLAN,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const importLiaisonDocumentCheckListPlan_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_LIAISON_DOC_CHECKLIST_PLAN,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFundSource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    sourceStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FUND_SOURCE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFundSourceSuccess(data));
        } else {
          dispatch(getProjectFundSourceSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFundSourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFundSourceSuccess = (data) => {
  return {
    type: GET_PROJECT_FUND_SOURCE_LIST,
    payload: data
  };
};
export const getProjectFundRequirment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FUND_REQUIREMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFundRequirmentSuccess(data));
        } else {
          dispatch(getProjectFundRequirmentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFundRequirmentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFundRequirmentSuccess = (data) => {
  return {
    type: GET_PROJECT_FUND_REQUIREMENT_DETAIL,
    payload: data
  };
};
export const updateProjectFundRequirement_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_FUND_REQUIREMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFund_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectFundID: "",
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FUND, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFundSuccess(data));
        } else {
          dispatch(getProjectFundSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFundSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFundSuccess = (data) => {
  return {
    type: GET_PROJECT_FUND_LIST,
    payload: data
  };
};
export const getProjectFundDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectFundID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FUND_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;

        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFundDetailSuccess(data));
        } else {
          dispatch(getProjectFundDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFundDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFundDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_FUND_DETAIL,
    payload: data
  };
};
export const getProjectFilePaymentDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_PAYEMENT_DETAIL,
    payload: data
  };
};
export const addLandOwnerPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_LAND_OWNER_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    projectFileID: "",
    paymentCategory: "",
    paymentAgainst: "",
    paymentType: "",
    requetedBy: "",
    recipientID: "",
    paymentStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdPaymentSuccess(data));
        } else {
          dispatch(getBdPaymentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdPaymentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentSuccess = (data) => {
  return {
    type: GET_BD_PAYMENT_LIST,
    payload: data
  };
};
export const getBdPaymentDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdPaymentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi._GET_BD_PAYMENT_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdPaymentDetailSuccess(data));
        } else {
          dispatch(getBdPaymentDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdPaymentDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentDetailSuccess = (data) => {
  return {
    type: GET_BD_PAYMENT_DETAIL,
    payload: data
  };
};
export const updateBdPaymentStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BD_PAYMENT_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentMilestone_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    milestoneType: "",
    milestoneStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PAYMENT_MILESTONE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_PAYMENT_MILESTONE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBroker_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    brokerID: "",
    projectID: "",
    projectFileID: "",
    brokerStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BROKER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_BROKER_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_BROKER_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_BROKER_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBrokerDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BROKER_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBrokerDetailSuccess(data));
        } else {
          dispatch(getProjectBrokerDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBrokerDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBrokerDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_BROKER_DETAIL,
    payload: data
  };
};
export const updateProjectBrokerStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_BROKER_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const addProjectBrokerCharge_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_PROJECT_BROKER_CHARGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectBrokerChargeStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_BROKER_CHARGE_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const addProjectBrokerPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_PROJECT_BROKER_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBrokerPaymentInfo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_PROJECT_BROKER_PAYMENT_INFO,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBrokerPaymentInfoSuccess(data));
        } else {
          dispatch(getProjectBrokerPaymentInfoSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBrokerPaymentInfoSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBrokerPaymentInfoSuccess = (data) => {
  return {
    type: GET_PROJECT_BROKER_PAYMENT_INFO,
    payload: data
  };
};
export const getProjectLandOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_LAND_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectLandOwnerSuccess(data));
        } else {
          dispatch(getProjectLandOwnerSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectLandOwnerSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLandOwnerSuccess = (data) => {
  return {
    type: GET_PROJECT_LAND_OWNER,
    payload: data
  };
};
export const getProjectOwnerSurveyNo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_OWNER_SURVEY_NUMBER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectOwnerSurveyNoSuccess(data));
        } else {
          dispatch(getProjectOwnerSurveyNoSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectOwnerSurveyNoSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectOwnerSurveyNoSuccess = (data) => {
  return {
    type: GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,
    payload: data
  };
};
export const getProjectFileAdvancePaymentDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileAdvancePaymentDetailSuccess(data));
        } else {
          dispatch(getProjectFileAdvancePaymentDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileAdvancePaymentDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileAdvancePaymentDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
    payload: data
  };
};
export const getProjectFilePaymentDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_PAYMENT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFilePaymentDetailSuccess(data));
        } else {
          dispatch(getProjectFilePaymentDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFilePaymentDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanFileSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanFileSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanFileSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFileSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_FILE,
    payload: data
  };
};
export const liaisonProcessPlanStart_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentProcessPlanID: "",
    projectFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LIAISON_PROCESS_PLAN_START,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplication_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    /*"projectID":"",
        "projectFileID":"",
        "processID":"",
        "completionStatus":"",
        "processOwnerID":"",
        "generalSearch":"",
        "sortOrder":"",
        "iDisplayStart":0,
        "iDisplayLength" : 10,*/
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_PROCESS_PLAN_APPLICATION,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_PROCESS_PLAN_APPLICATION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_PROCESS_PLAN_APPLICATION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessPlanApplicationOwner = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanApplicationID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicationFileDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanApplicationFileDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicationFileDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
    payload: data
  };
};
export const liaisonProcessPlanSplit_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LIAISON_PROCESS_PLAN_SPLIT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessPlanApplicationStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //"taskType":"ProjectTask",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_APPLICATION_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMilestone_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    planningAuthorityID: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECTC_MILESTONE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectMilestoneSuccess(data));
        } else {
          dispatch(getProjectMilestoneSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMilestoneSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMilestoneSuccess = (data) => {
  return {
    type: GET_PROJECTC_MILESTONE,
    payload: data
  };
};
export const getBdRegistrationItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_REGISTRATION_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationItemSuccess(data));
        } else {
          dispatch(getBdRegistrationItemSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationItemSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_ITEM,
    payload: data
  };
};
export const getSubRegisterOffice_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SUBREGISTER_OFFICE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getSubRegisterOfficeSuccess(data));
        } else {
          dispatch(getSubRegisterOfficeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getSubRegisterOfficeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSubRegisterOfficeSuccess = (data) => {
  return {
    type: GET_SUBREGISTER_OFFICE,
    payload: data
  };
};
export const addLandGovtPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_LAND_GOVT_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateSurveyNumberCreation_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_SURVEY_NUMBER_CREATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const uploadProjectFileDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("projectFileID", data.projectFileID);
  // for (let index = 0; index < data.documentFile.length; index++) {
  //     initialPayload.append('documentFile[]', data.documentFile[index]);
  // }
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("documentRemark", data.documentRemark);
  // initialPayload.append('documentTitle', data.documentTitle);
  // initialPayload.append('documentType',  data.documentType);
  // console.log("initialPayload", Array.from(initialPayload));
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_PROJECT_FILE_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileDocument_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectFileID: "",
    projectID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FILE_DOCUMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileDocumentSuccess(data));
        } else {
          dispatch(getProjectFileDocumentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileDocumentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileDocumentSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_DOCUMENT,
    payload: data
  };
};
export const updateProjectFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectFileStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_FILE_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDependentRelationship_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_DEPENDENT_RELATIONSHIP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDependentRelationshipSuccess(data));
        } else {
          dispatch(getDependentRelationshipSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDependentRelationshipSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDependentRelationshipSuccess = (data) => {
  return {
    type: GET_DEPENDENT_RELATIONSHIP,
    payload: data
  };
};
export const updateLandOwner_action = (data) => {
  let initialPayload;
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("landOwnerID", data.landOwnerID);
  initialPayload.append("parentRelationship", data.parentRelationship);
  initialPayload.append("ownerName", data.ownerName);
  initialPayload.append("mobileNumber", data.mobileNumber);
  initialPayload.append("isPanPresent", data.isPanPresent);
  initialPayload.append("isAadharPresent", data.isAadharPresent);
  initialPayload.append("aadharNumber", data.aadharNumber);
  initialPayload.append("panNumber", data.panNumber);
  initialPayload.append("ownerRemarks", data.ownerRemarks);
  initialPayload.append("panDocumentFile", data?.panDocumentFile);
  initialPayload.append("aadharDocumentFile", data?.aadharDocumentFile);
  const config = data?.panDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLandOwnerStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //"taskType":"ProjectTask",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_OWNER_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLandBroker_action = (data) => {
  let initialPayload;
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("brokerID", data.brokerID);
  initialPayload.append("brokerName", data.brokerName);
  initialPayload.append("mobileNumber", data.mobileNumber);
  initialPayload.append("isPanPresent", data.isPanPresent);
  initialPayload.append("isAadharPresent", data.isAadharPresent);
  initialPayload.append("aadharNumber", data.aadharNumber);
  initialPayload.append("panNumber", data.panNumber);
  initialPayload.append("brokerRemarks", data.brokerRemarks);
  initialPayload.append("panDocumentFile", data?.panDocumentFile);
  initialPayload.append("aadharDocumentFile", data?.aadharDocumentFile);
  const config = data?.panDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_BROKER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLandBrokerStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_BROKER_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentAdvanceDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdPaymentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi._GET_BD_PAYMENT_ADVANCE_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdPaymentAdvanceDetailSuccess(data));
        } else {
          dispatch(getBdPaymentAdvanceDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdPaymentAdvanceDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentAdvanceDetailSuccess = (data) => {
  return {
    type: GET_BD_PAYMENT_ADVANCE_DETAIL,
    payload: data
  };
};
export const bdLandPaymentSettle_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.BD_LAND_PAYMENT_SETTLE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ownershipID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDetailSuccess(data));
        } else {
          dispatch(getProjectDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_DETAIL,
    payload: data
  };
};
export const getProjectDropReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DROP_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDropReasonSuccess(data));
        } else {
          dispatch(getProjectDropReasonSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDropReasonSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDropReasonSuccess = (data) => {
  return {
    type: GET_PROJECT_DROP_REASON_LIST,
    payload: data
  };
};
export const updateProjectStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",
    reasonID: "",
    statusRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectBdOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    bdOwnerID: "",
    projectRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_BD_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectLegalOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    legalOwnerID: "",
    projectRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_LEGAL_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectName: "",
    planningAuthorityID: "",
    projectBrokerID: "",
    districtID: "",
    talukID: "",
    hobliID: "",
    villageID: "",
    projectRemark: "",
    projectZoneID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCompanyProjectMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    mapStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.COMPANY_PROJECT_MAP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COMPANY_PROJECT_MAP,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COMPANY_PROJECT_MAP,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COMPANY_PROJECT_MAP,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectCompanyMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyProjectID: "",
    mapStatus: "",
    remark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_COMPANY_MAP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const addProjectCompanyMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    remark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_PROJECT_COMPANY_MAP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCompany_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    companyStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COMPANY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COMPANY,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COMPANY,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COMPANY,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDroppedProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ownershipID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_DROPPED_PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDroppedProjectSuccess(data));
        } else {
          dispatch(getDroppedProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDroppedProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDroppedProjectSuccess = (data) => {
  return {
    type: GET_DROPPED_PROJECT_LIST,
    payload: data
  };
};

export const getDroppedProjectExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ownershipID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_DROPPED_PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDroppedProjectExportSuccess(data));
        } else {
          dispatch(getDroppedProjectExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDroppedProjectExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDroppedProjectExportSuccess = (data) => {
  return {
    type: GET_DROPPED_PROJECT_LIST_EXPORT,
    payload: data
  };
};
export const getLandOwnerAccountDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_OWNER_ACCOUNT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandOwnerAccountDetailSuccess(data));
        } else {
          dispatch(getLandOwnerAccountDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandOwnerAccountDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandOwnerAccountDetailSuccess = (data) => {
  return {
    type: GET_LAND_OWNER_ACCOUNT_DETAIL,
    payload: data
  };
};
export const updateLandOwnerAccount_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("accountID", data.accountID);
  initialPayload.append("accountNumber", data.accountNumber);
  initialPayload.append("accountName", data.accountName);
  initialPayload.append("accountBank", data.accountBank);
  initialPayload.append("accountBranch", data.accountBranch);
  initialPayload.append("accountIfscCode", data.accountIfscCode);
  initialPayload.append("accountStatus", data.accountStatus);
  initialPayload.append("isDocumentChanged", data.isDocumentChanged);
  initialPayload.append("passBookDocumentFile", data?.passBookDocumentFile);
  const config = data?.passBookDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LAND_OWNER_ACCOUNT_DETAIL,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandBrokerAccountDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_BROKER_ACCOUNT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandBrokerAccountDetailSuccess(data));
        } else {
          dispatch(getLandBrokerAccountDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandBrokerAccountDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandBrokerAccountDetailSuccess = (data) => {
  return {
    type: GET_LAND_BROKER_ACCOUNT_DETAIL,
    payload: data
  };
};
export const updateLandBrokerAccount_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("accountID", data.accountID);
  initialPayload.append("accountNumber", data.accountNumber);
  initialPayload.append("accountName", data.accountName);
  initialPayload.append("accountBank", data.accountBank);
  initialPayload.append("accountBranch", data.accountBranch);
  initialPayload.append("accountIfscCode", data.accountIfscCode);
  initialPayload.append("accountStatus", data.accountStatus);
  initialPayload.append("isDocumentChanged", data.isDocumentChanged);
  initialPayload.append("passBookDocumentFile", data?.passBookDocumentFile);
  const config = data?.passBookDocumentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LAND_BROKER_ACCOUNT_DETAIL,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectID: "",
    processID: "",
    processOwnerID: "",
    processStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicationFilterOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectID: "",
    processStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanFilterOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectID: "",
    projectFileID: "",
    taskStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
// -----
export const getUserProfile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.USER_PROFILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_USER_PROFILE,
            payload: data
          });
        } else {
          dispatch({
            type: GET_USER_PROFILE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_USER_PROFILE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateEmployeeProfile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    profileID: "",
    employeeName: "",
    fatherName: "",
    motherName: "",
    employeeDateOfBirth: "",
    employeeGender: "",
    employeeBloodGroup: "",
    employeeMaritialStatus: "",
    personalPhoneNumber: "",
    personalEmailId: "",
    employeePanNumber: "",
    employeeAadharNumber: "",
    currentAddress: "",
    permanentAddress: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_EMPLOYEE_PROFILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// ---
export const updateUserProfileImage_action = (data) => {
  let initialPayload;
  // if(data?.documentFile){
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("profileID", data.profileID);
  initialPayload.append("userProfileImage", data?.userProfileImage);
  const config = data?.userProfileImage
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_USER_PROFILE_IMAGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBithday_Action = (data) => {
  const initialPayload = {
    userID: "145114052227179500",
    ...data
  };
  console.log(initialPayload, "initialPayload");
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BIRTHDAY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBirthDay(data));
        } else {
          dispatch(getBirthDay({}));
        }
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(getBirthDay());
        dispatch(getBirthDay());
      });
  };
};
const getBirthDay = (data) => {
  return {
    type: GET_BIRTHDAY,
    payload: data
  };
};
export const updateUserGreetingImage_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", "145114052227179500");
  initialPayload.append("profileID", data.profileID);
  initialPayload.append("userGreetingImage", data.userGreetingImage);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_USER_GREETING_IMAGE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAnniversary_Action = (data) => {
  const initialPayload = {
    userID: 145114052227179500,
    ...data
  };
  console.log(initialPayload, "initialPayload");
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ANNIVERSARY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAnniversary(data));
        } else {
          dispatch(getAnniversary({}));
        }
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(getAnniversary());
        dispatch(getAnniversary());
      });
  };
};
const getAnniversary = (data) => {
  return {
    type: GET_EMPLOYEE_WORK_ANNIVERSARY,
    payload: data
  };
};
export const updateUserAnniversaryGreetingImage_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", "145114052227179500");
  initialPayload.append("profileID", data.profileID);
  initialPayload.append("userGreetingImage", data.userGreetingImage);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_USER_ANNIVERSARY_GREETING_IMAGE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "Active",
    sortOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectListFilterSuccess(data));
        } else {
          dispatch(getProjectListFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectListFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectListFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_LIST_FILTER,
    payload: data
  };
};
export const getProjectUpcomingFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    sortOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_UPCOMING_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectUpcomingFilterSuccess(data));
        } else {
          dispatch(getProjectUpcomingFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectUpcomingFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectUpcomingFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_UPCOMING_FILTER,
    payload: data
  };
};
export const getCivilProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    sortOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectFilterSuccess(data));
        } else {
          dispatch(getCivilProjectFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectFilterSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_FILTER,
    payload: data
  };
};
export const getProjectFileFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    fileStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FILE_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_FILE_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_FILE_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_FILE_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessFilterSuccess(data));
        } else {
          dispatch(getLiaisonProcessFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessFilterSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_FILTER,
    payload: data
  };
};
export const getApfPanel_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    sortOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfPanelSuccess(data));
        } else {
          dispatch(getApfPanelSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfPanelSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfPanelSuccess = (data) => {
  return {
    type: GET_APF_PANEL,
    payload: data
  };
};
export const getApfPendingProjectFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_PENDING_PROJECT_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfPendingProjectFileSuccess(data));
        } else {
          dispatch(getApfPendingProjectFileSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfPendingProjectFileSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfPendingProjectFileSuccess = (data) => {
  return {
    type: GET_APF_PENDING_PROJECT_FILE,
    payload: data
  };
};
export const createApfTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    panelID: "",
    projectFileID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APF_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    panelID: "",
    taskID: "",
    projectFileID: "",
    taskOwnerID: "",
    taskAccountableID: "",
    taskStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanSuccess(data));
        } else {
          dispatch(getApfTaskPlanSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN,
    payload: data
  };
};
export const getApfTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanDetailSuccess(data));
        } else {
          dispatch(getApfTaskPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanDetailSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_DETAIL,
    payload: data
  };
};
export const updateApfTaskRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //"taskType":"ProjectTask",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK_REMARK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadApfTaskDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  // initialPayload.append('documentType',  data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  // initialPayload.append('documentFile', data.documentFile);
  // initialPayload.append('taskType', data?.taskTypeID ? data?.taskTypeID : "ProjectTask");
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_APF_TASK_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanStart_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_START, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanComplete_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("taskCompletedDate", data.taskCompletedDate);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("documentRemark", "");
  initialPayload.append("projectFileID", JSON.stringify(data.projectFileID));
  // initialPayload.append('documentType',  data.documentType);
  // initialPayload.append('documentFile', data.documentFile);
  // initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_APF_TASK_PLAN_COMPLETE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    panelID: "",
    taskOwnerID: "",
    taskStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_PROJECT_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanProjectFilterSuccess(data));
        } else {
          dispatch(getApfTaskPlanProjectFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanProjectFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanProjectFilterSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_PROJECT_FILTER,
    payload: data
  };
};
export const getApfTaskPlanPanelFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    panelID: "",
    projectFileID: "",
    taskOwnerID: "",
    taskStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_PANEL_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanPanelFilterSuccess(data));
        } else {
          dispatch(getApfTaskPlanPanelFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanPanelFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanPanelFilterSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_PANEL_FILTER,
    payload: data
  };
};
export const getApfTaskPlanProjectFileFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    panelID: "",
    projectFileID: "",
    taskOwnerID: "",
    taskStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanProjectFileFilterSuccess(data));
        } else {
          dispatch(getApfTaskPlanProjectFileFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanProjectFileFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanProjectFileFilterSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,
    payload: data
  };
};
export const getApfTaskPlanTaskFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    panelID: "",
    projectFileID: "",
    taskOwnerID: "",
    taskStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_TASK_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanTaskFilterSuccess(data));
        } else {
          dispatch(getApfTaskPlanTaskFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanTaskFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanTaskFilterSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_TASK_FILTER,
    payload: data
  };
};
export const getApfTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    panelID: "",
    taskID: "",
    taskOwnerID: "",
    taskStatus: "",
    statusID: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskSuccess(data));
        } else {
          dispatch(getApfTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskSuccess = (data) => {
  return {
    type: GET_APF_TASK_FILTER,
    payload: data
  };
};
export const getApfTaskPlanQuery_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectID: "",
    projectFileID: "",
    taskID: "",
    panelID: "",
    queryOwnerID: "",
    queryAccountableID: "",
    queryStatus: "",
    fromDate: "",
    toDate: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_QUERY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQuerySuccess(data));
        } else {
          dispatch(getApfTaskPlanQuerySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQuerySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQuerySuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY,
    payload: data
  };
};
export const createApfTaskPlanQuery_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("apfTaskPlanID", data.apfTaskPlanID);
  initialPayload.append("taskOwnerID", data.taskOwnerID);
  initialPayload.append("queryDescription", data.queryDescription);
  initialPayload.append("projectFileID", data.projectFileID);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_APF_TASK_PLAN_QUERY,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadApfTaskQueryDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("taskPlanQueryID", data.taskPlanQueryID);
  // initialPayload.append('documentType',  data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  // initialPayload.append('documentFile', data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_APF_TASK_QUERY_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    apfQueryID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_TASK_PLAN_QUERY_DETAIL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_TASK_PLAN_QUERY_DETAIL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_TASK_PLAN_QUERY_DETAIL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanQueryOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanQueryID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_APF_TASK_PLAN_QUERY_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanQueryStatus_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("taskPlanQueryID", data.taskPlanQueryID);
  initialPayload.append("queryStatusID", data.queryStatusID);
  initialPayload.append("documentType", data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("documentFile", data.documentFile);
  // initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_APF_TASK_PLAN_QUERY_STATUS,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateApfTaskPlanQueryStatusAll_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append(
    "taskPlanQueryID",
    JSON.stringify(data.taskPlanQueryID)
  );
  initialPayload.append("queryStatusID", data.queryStatusID);
  initialPayload.append("documentType", data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("documentFile", data.documentFile);
  // initialPayload.append('subTaskPlanID', JSON.stringify(data.subTaskPlanID));
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_APF_TASK_PLAN_QUERY_STATUS_ALL,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLiaisonDocumentationReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    taskPlanStatus: "",
    documentType: "",
    taskOwnerID: "",
    categoryID: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDocumentationReportSuccess(data));
        } else {
          dispatch(getLiaisonDocumentationReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDocumentationReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationReportSuccess = (data) => {
  return {
    type: GET_LIAISON_DOCUMENTATION_REPORT,
    payload: data
  };
};
export const getBdLandDisputeType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    issueTypeID: "Dispute",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeTypeSuccess(data));
        } else {
          dispatch(getBdLandDisputeTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeTypeSuccess = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_TYPE,
    payload: data
  };
};
export const createBdLandDispute_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("projectFileID", data.projectFileID);
  initialPayload.append("issueTypeID", data.issueTypeID);
  initialPayload.append("handledByID", data.handledByID);
  initialPayload.append("issueDescription", data.issueDescription);
  initialPayload.append("documentFile", data.documentFile);
  initialPayload.append("issueCategoryID", data.issueCategoryID);
  initialPayload.append("issuePriorityID", data.issuePriorityID);
  initialPayload.append("accountableID", data.accountableID);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.CREATE_BD_LAND_DISPUTE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDispute_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    issueTypeID: "",
    issueHandledBy: "",
    issueStatus: "",
    accountableID: "",
    issueCategoryID: "",
    issuePriorityID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeSuccess(data));
        } else {
          dispatch(getBdLandDisputeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeSuccess = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_LIST,
    payload: data
  };
};
export const uploadBdLandDisputDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("disputeID", data.disputeID);
  initialPayload.append("documentType", data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("documentFile", data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_BD_LAND_DISPUTE_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landDisputeID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_LAND_DISPUTE_DETAIL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_LAND_DISPUTE_DETAIL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_LAND_DISPUTE_DETAIL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdLandDisputOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landDisputeID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_DISPUTE_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdLandDisputRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landDisputeID: "",
    nextFollowUpDate: "",
    followUpRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_DISPUTE_REMARK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdLandDisputStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landDisputeID: "",
    disputeStatusID: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_LAND_DISPUTE_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationReportProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationReportProjectFileFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    fileStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationReportCategoryFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationReportOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseTypeSuccess(data));
        } else {
          dispatch(getCourtCaseTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseTypeSuccess = (data) => {
  return {
    type: GET_COURT_CASE_TYPE,
    payload: data
  };
};
/*export const getCourtCaseHandledByFilter_action = (data) => {
    const initialPayload ={
        "userID": LoginuserId,
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COURT_CASE_HANDLED_BY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getBdHandleSuccess(data))
            }
            else{
                dispatch(getBdHandleSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getBdHandleSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getBdHandleSuccess = (data) => {
    return {
        type : GET_COURT_CASE_HANDLED_BY,
        payload : data
    }
}*/
export const getCourtCaseHandledByFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_HANDLED_BY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdHandleSuccess(data));
        } else {
          dispatch(getBdHandleSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdHandleSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdHandleSuccess = (data) => {
  return {
    type: GET_COURT_CASE_HANDLED_BY,
    payload: data
  };
};
export const getBdLandDisputeHandledByFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    issueCategoryID: "",
    adminView: "",
    reportView: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_LAND_DISPUTE_HANDLED_BY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeHandledByFilterSuccess(data));
        } else {
          dispatch(getBdLandDisputeHandledByFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeHandledByFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeHandledByFilterSuccess = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_HANDLED_BY,
    payload: data
  };
};
export const getCourtCaseStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseStatusSuccess(data));
        } else {
          dispatch(getCourtCaseStatusSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseStatusSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseStatusSuccess = (data) => {
  return {
    type: GET_COURT_CASE_STATUS,
    payload: data
  };
};
export const getCourtCaseJurisdiction_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_COURT_CASE_JURISDICTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseJurisdictionSuccess(data));
        } else {
          dispatch(getCourtCaseJurisdictionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseJurisdictionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseJurisdictionSuccess = (data) => {
  return {
    type: GET_COURT_CASE_JURISDICTION,
    payload: data
  };
};
export const getDirector_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_DIRECTOR, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDirectorSuccess(data));
        } else {
          dispatch(getDirectorSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDirectorSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDirectorSuccess = (data) => {
  return {
    type: GET_DIRECTOR,
    payload: data
  };
};
// export const createCourtCase_action = (data) => {
//     const initialPayload = {
//         "userID": LoginuserId,
//         ...data
//     }
//     return dispatch => {
//         dispatch(mainLoadingTrue());
//         return ErpApiService.post(ErpApi.CREATE_COURT_CASE, initialPayload).then(response => {
//             const {data}  = response;
//             dispatch(mainLoadingFalse());
//             if(!data.error)
//             {
//                 // toast.success(data.message);
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "success"
//                 }));
//             }
//             else{
//                 // toast.error(data.message);
//                 dispatch(displayMessage({
//                     text : data.message,
//                     type : "error"
//                 }));
//             }
//         })
//         .catch(err => {
//             dispatch(mainLoadingFalse());
//         })
//     }
// }
export const createCourtCase_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("caseOn", data.caseOn);
  initialPayload.append("caseOnID", JSON.stringify(data.caseOnID));
  initialPayload.append("caseTypeID", data.caseTypeID);
  initialPayload.append("caseNumber", data.caseNumber);
  initialPayload.append("caseJurisdiction", data.caseJurisdiction);
  initialPayload.append("caseStatus", data.caseStatus);
  initialPayload.append("fillingDate", data.fillingDate);
  initialPayload.append("casePlaintiff", data.casePlaintiff);
  initialPayload.append("caseDefendant", data.caseDefendant);
  initialPayload.append("caseNature", data.caseNature);
  initialPayload.append("caseDescription", data.caseDescription);
  initialPayload.append("handledByID", data.handledByID);
  initialPayload.append("accountableID", data.accountableID);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.CREATE_COURT_CASE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCase_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    caseOn: "",
    projectID: "",
    caseTypeID: "",
    caseHandledBy: "",
    caseStatus: "",
    caseActiveStatus: "",
    caseJurisdiction: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseSuccess(data));
        } else {
          dispatch(getCourtCaseSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseSuccess = (data) => {
  return {
    type: GET_COURT_CASE,
    payload: data
  };
};
export const uploadCourtCaseDocument_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("courtCaseID", data.courtCaseID);
  initialPayload.append("documentType", data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  // initialPayload.append('documentFile', data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_COURT_CASE_DOCUMENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_OWNER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    courtCaseStatusID: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    nextFollowUpDate: "",
    followUpRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_REMARK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_CASE_DETAIL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_CASE_DETAIL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_CASE_DETAIL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCase_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskPlanStart_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_PROJECT_TASK_PLAN_START,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createTrainingContent_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("functionalityID", data.functionalityID);
  initialPayload.append("videoDescription", data.videoDescription);
  initialPayload.append("videoFile", data.videoFile);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.CREATE_TRAINING_CONTENT,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getTrainingContent_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    functionalityID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TRAINING_CONTENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getTrainingContentSuccess(data));
        } else {
          dispatch(getTrainingContentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getTrainingContentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getTrainingContentSuccess = (data) => {
  return {
    type: GET_TRAINING_CONTENT,
    payload: data
  };
};
export const updateTrainingContent_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    trainingContentID: "",
    videoDescription: "",
    statusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_TRAINING_CONTENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const deleteProjectFileDocument_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    documentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_PROJECT_FILE_DOCUMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskRejectionReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonID: "",
    reasonCategory: "All",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_TASK_REJECTION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_PROJECT_TASK_REJECTION_REASON,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_PROJECT_TASK_REJECTION_REASON,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_PROJECT_TASK_REJECTION_REASON,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdAgreementType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    agreementTypeID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_AGREEMENT_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_AGREEMENT_TYPE,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_BD_AGREEMENT_TYPE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_AGREEMENT_TYPE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectReportSuccess(data));
        } else {
          dispatch(getBdProjectReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectReportSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_REPORT,
    payload: data
  };
};
export const getBdProjectTaskReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectTaskReportSuccess(data));
        } else {
          dispatch(getBdProjectTaskReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectTaskReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskReportSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_REPORT,
    payload: data
  };
};
export const getBdProjectTaskReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectTaskReportExportSuccess(data));
        } else {
          dispatch(getBdProjectTaskReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectTaskReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskReportExportSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_REPORT_EXPORT,
    payload: data
  };
};
export const createProjectProposedName_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectName: "",
    projectNameDescription: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_PROPOSED_NAME,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectProposedName_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_PROPOSED_NAME, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectProposedNameSuccess(data));
        } else {
          dispatch(getProjectProposedNameSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectProposedNameSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectProposedNameSuccess = (data) => {
  return {
    type: GET_PROJECT_PROPOSED_NAME,
    payload: data
  };
};
export const deleteProjectProposedName_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_PROJECT_PROPOSED_NAME,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectProposedNameStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_PROPOSED_NAME_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// -----------------
///delete content
export const deleleTrainingContent_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    trainingContentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_TRANING_CONTENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// getMyTraning
export const getMyTraining_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    functionalityID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TRAINING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getMyTraning(data));
        } else {
          dispatch(getMyTraning({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getMyTraning([]));
        dispatch(mainLoadingFalse());
      });
  };
};
const getMyTraning = (data) => {
  return {
    type: GET_TRAINING,
    payload: data
  };
};
// multiple document upload
export const uploadProjectTaskDocuments_action = (data) => {
  let initialPayload = new FormData();
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("userID", LoginuserId);
  // initialPayload.append('documentType',  data.documentType);
  initialPayload.append("documentRemark", data.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  // initialPayload.append('documentFile', data.documentFile);
  initialPayload.append(
    "taskType",
    data?.taskTypeID ? data?.taskTypeID : "ProjectTask"
  );
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_PROJECT_UPLOAD_DOCUMENTS,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectSubTaskCompleteStatus_action = (data) => {
  let initialPayload = new FormData();
  // initialPayload.append('documentType',  37);
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("taskCompletedDate", data.taskCompletedDate);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("subTaskPlanID", JSON.stringify(data.subTaskPlanID));
  initialPayload.append("documentRemark", data.documentRemark);
  // initialPayload.append('documentFile', data.documentFile);
  initialPayload.append("taskType", data.taskType);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_PROJECT_SUB_TASK_COMPLETE_STATUS,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
// ---------------------- 18 july
export const getLiaisonProcessPlanProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    issueTypeID: "",
    issueHandledBy: "",
    issueStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanProjectSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanProjectSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_PROJECT,
    payload: data
  };
};
export const getLiaisonProcessPlanProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanProcessSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanProcessSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanProcessSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_PROCESS,
    payload: data
  };
};
export const createLiaisonPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    paymentTypeID: "",
    projectID: "",
    processID: "",
    companyID: "",
    paymentRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LIAISON_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    paymentTypeID: "",
    projectID: "",
    processID: "",
    companyID: "",
    requestedByID: "",
    paymentStatusID: "",
    settlementStatusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentSuccess(data));
        } else {
          dispatch(getLiaisonPaymentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_LIST,
    payload: data
  };
};
export const getLiaisonPaymentDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentDetailSuccess(data));
        } else {
          dispatch(getLiaisonPaymentDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_DETAIL,
    payload: data
  };
};
export const getLiaisonPaymentSurveyNumber_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PAYMENT_SURVEY_NUMBER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentSurveyNumberSuccess(data));
        } else {
          dispatch(getLiaisonPaymentSurveyNumberSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentSurveyNumberSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentSurveyNumberSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_SURVEY_NUMBER,
    payload: data
  };
};
export const getLiaisonPaymentTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    processPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PAYMENT_TASK_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentTaskPlanSuccess(data));
        } else {
          dispatch(getLiaisonPaymentTaskPlanSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentTaskPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentTaskPlanSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_TASK_PLAN,
    payload: data
  };
};
export const addLiaisonPaymentItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    taskPlanIDs: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_LIAISON_PAYMENT_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const deleteLiaisonPayment_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_LIAISON_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const deleteLiaisonPaymentItem_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentItemID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_LIAISON_PAYMENT_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonPaymentStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    paymentStatusID: "",
    statusRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PAYMENT_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonPaymentIssue_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    paymentStatusID: "",
    companyID: "",
    issueRemark: "",
    paymentMode: "",
    instrumentDate: "",
    instrumentDetail: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PAYMENT_ISSUE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonPaymentItemActualAmount_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    updateRemark: "",
    taskPlanIDs: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PAYMENT_ITEM_ACTUAL_AMOUNT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonPaymentSettlementApproval_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    paymentStatusID: "",
    statusRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PAYMENT_SETTLEMENT_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonPaymentSettlementSettle_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonPaymentID: "",
    paymentStatusID: "",
    statusRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PAYMENT_SETTLEMENT_SETTLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFunctionalityPermissionDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    functionalityID: "",
    functionalityItemIDs: [
      { functionalityID: 61, functionalityName: "Request" },
      {
        functionalityID: 62,
        functionalityName: "Approved"
      },
      { functionalityID: 63, functionalityName: "Issue" }
    ],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_FUNCTIONALITY_PERMISSION_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFunctionalityPermissionDetailSuccess(data));
        } else {
          dispatch(getFunctionalityPermissionDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFunctionalityPermissionDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFunctionalityPermissionDetailSuccess = (data) => {
  return {
    type: GET_FUNCTIONALITY_PERMISSION_DETAIL,
    payload: data
  };
};
export const resetFunctionalityPermissionDetail_action = () => {
  return {
    type: GET_FUNCTIONALITY_PERMISSION_DETAIL,
    payload: []
  };
};
export const getLiaisonPaymentFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    paymentTypeID: "",
    companyID: "",
    projectID: "",
    processID: "",
    requestedByID: "",
    paymentStatusID: "",
    settlementStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PAYMENT_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentFilterProjectSuccess(data));
        } else {
          dispatch(getLiaisonPaymentFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentFilterProjectSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_FILTER_PROJECT,
    payload: data
  };
};
export const getLiaisonPaymentFilterRequestedBy_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    paymentTypeID: "",
    companyID: "",
    projectID: "",
    processID: "",
    requestedByID: "",
    paymentStatusID: "",
    settlementStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentFilterRequestedBySuccess(data));
        } else {
          dispatch(getLiaisonPaymentFilterRequestedBySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentFilterRequestedBySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentFilterRequestedBySuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,
    payload: data
  };
};
export const getProjectTaskPlanFilterTaskOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    taskOwnerID: "",
    taskPlanStatusID: "",
    adminView: "",
    reportView: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    taskOwnerID: "",
    taskPlanStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_FILTER_TASK,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_PLAN_FILTER_TASK,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_PLAN_FILTER_TASK,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanStart_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("documentRemark", data?.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_START,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanOwners_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskPlanOwnerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_OWNERS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanPending_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: [],
    categoryID: "",
    documentType: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonApplicableProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    applicabilityStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_APPLICABLE_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_APPLICABLE_PROCESS_PLAN,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_APPLICABLE_PROCESS_PLAN,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_APPLICABLE_PROCESS_PLAN,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    accountableID: "",
    projectID: "",
    projectFileID: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    applicableStatus: "",
    categoryID: "",
    reportData: "",
    documentType: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIASION_DOCUMENATAION_TASK_PLAN_EXPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDepartment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    departmentID: "",
    departmentStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_DEPARTMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getDepartmentSuccess(data));
        } else {
          dispatch(getDepartmentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getDepartmentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getDepartmentSuccess = (data) => {
  return {
    type: GET_DEPARTMENT,
    payload: data
  };
};
export const deleteProjectDocument_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    documentID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_PROJECT_DOCUMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonDocumentTaskPlan_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonTaskID: "",
    taskName: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_DOCUMENT_TASK_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApprovalDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    documentTaskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess(data));
        } else {
          dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDocumentationTaskPlanApprovalDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApprovalDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,
    payload: data
  };
};
export const getLiaisonDocumentationTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    documentTaskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDocumentationTaskPlanDetailSuccess(data));
        } else {
          dispatch(getLiaisonDocumentationTaskPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDocumentationTaskPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL,
    payload: data
  };
};
export const updateNotificationViewStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    notificationID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_NOTIFICATION_VIEW_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getMyWebNotification_action());
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTaskPlanQuery_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    apfTaskPlanQueryID: "",
    apfTaskPlanQuery: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK_PLAN_QUERY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanFilterTaskCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    taskOwnerID: "",
    taskPlanStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskPlanFilterTaskCategorySuccess(data));
        } else {
          dispatch(getProjectTaskPlanFilterTaskCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskPlanFilterTaskCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanFilterTaskCategorySuccess = (data) => {
  return {
    type: GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY,
    payload: data
  };
};
export const getLiaisonProcessPlanApplicationDetail1_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonProcessPlanApplicationID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanApplicationDetail1Success(data));
        } else {
          dispatch(getLiaisonProcessPlanApplicationDetail1Success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanApplicationDetail1Success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicationDetail1Success = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1,
    payload: data
  };
};
export const getLiaisonTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_TASK_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonTaskPlanDetailSuccess(data));
        } else {
          dispatch(getLiaisonTaskPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonTaskPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskPlanDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_TASK_PLAN_DETAIL,
    payload: data
  };
};
export const updateLiaisonTaskPlanApplicabilityStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonTaskPlanID: "",
    applicabilityStatus: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_APPLICABILITY_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanPendingDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanPendingDetailSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanPendingDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanPendingDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanPendingDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL,
    payload: data
  };
};
export const getLiaisonTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    taskStatusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonTaskSuccess(data));
        } else {
          dispatch(getLiaisonTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskSuccess = (data) => {
  return {
    type: GET_LIAISON_TASK,
    payload: data
  };
};
export const getLiaisonProcessPlanPendingTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    // "taskStatusID":"",
    // "generalSearch":"",
    // "sortOrder":"",
    // "iDisplayStart":"0",
    // "iDisplayLength":"-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_PENDING_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanPendingTaskSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanPendingTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanPendingTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanPendingTaskSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_PENDING_TASK,
    payload: data
  };
};
export const addLiaisonProcessPlanTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentProcessPlanID: "",
    liaisonTaskID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LIAISON_PROCESS_PLAN_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processStatus: "",
    processCategory: "",
    processOwner: "",
    isMandatory: "",
    completionType: "",
    executionType: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessSuccess(data));
        } else {
          dispatch(getLiaisonProcessSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS,
    payload: data
  };
};
export const getLiaisonProcessCategory1_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_CATEGORY1,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessCategory1Success(data));
        } else {
          dispatch(getLiaisonProcessCategory1Success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessCategory1Success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessCategory1Success = (data) => {
  return {
    type: GET_LIAISON_PROCESS_CATEGORY1,
    payload: data
  };
};
export const createLiaisonProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processCategoryID: "",
    ownerID: "",
    isMandatory: "",
    processName: "",
    completionType: "",
    executionType: "",
    taskPriority: "",
    processOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LIAISON_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessOptional_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    mandatoryType: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_OPTIONAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessOptionalSuccess(data));
        } else {
          dispatch(getLiaisonProcessOptionalSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessOptionalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessOptionalSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_OPTIONAL,
    payload: data
  };
};
export const getLiaisonProcessSurveyNumberOptional_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessSurveyNumberOptionalSuccess(data));
        } else {
          dispatch(getLiaisonProcessSurveyNumberOptionalSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessSurveyNumberOptionalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessSurveyNumberOptionalSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL,
    payload: data
  };
};
export const addLiaisonProcessPlanExtra_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    processOwner: "",
    projectFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LIAISON_PROCESS_PLAN_EXTRA,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonProcessID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectTaskID: "",
    processAccountableID: "",
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    acquisitionStatus: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    projectFileStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN,
    payload: data
  };
};
export const getLiaisonProcessPlanApproval_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    isAdmin: "",
    processApproverID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanApprovalSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanApprovalSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanApprovalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApprovalSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPROVAL,
    payload: data
  };
};
export const getLiaisonProcessPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanDetailSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanDetailSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_DETAIL,
    payload: data
  };
};
export const getLiaisonProcessPlanFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    processOwnerID: "",
    processStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanFilterProjectSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterProjectSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT,
    payload: data
  };
};
export const getLiaisonProcessPlanFilterProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    applicabilityStatus: "Applicable",
    acquisitionStatus: "",
    processID: "",
    processOwnerID: "",
    processStatus: "",
    projectFileStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanFilterProcessSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanFilterProcessSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanFilterProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterProcessSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS,
    payload: data
  };
};
export const updateLiaisonProcessPlanStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonProcessPlanID: "",
    statusID: "",
    updateRemark: "",
    rejectionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const uploadNewsLetter_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("newsLetterMonth", data.newsLetterMonth);
  initialPayload.append("newsLetterTitle", data.newsLetterTitle);
  initialPayload.append("documentFile", data.documentFile);
  initialPayload.append("categoryID", data.categoryID);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_NEWS_LETTER,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getNewsLetter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    newsLatterMonth: "",
    newsLatterStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_NEWS_LETTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getNewsLetterSuccess(data));
        } else {
          dispatch(getNewsLetterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getNewsLetterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getNewsLetterSuccess = (data) => {
  return {
    type: GET_NEWS_LETTER,
    payload: data
  };
};
export const sendNewsLetterWhatsApp_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    newsLetterID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.SEND_NEWS_LETTER_WHATSAPP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const sendNewsLetterEmail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    newsLetterID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.SEND_NEWS_LETTER_EMAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessIdentificationPendingFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessIdentificationPendingFileSuccess(data));
        } else {
          dispatch(getLiaisonProcessIdentificationPendingFileSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessIdentificationPendingFileSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessIdentificationPendingFileSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE,
    payload: data
  };
};
export const getLiaisonProcessIdentificationPendingCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessIdentificationPendingCategorySuccess(data));
        } else {
          dispatch(getLiaisonProcessIdentificationPendingCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessIdentificationPendingCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessIdentificationPendingCategorySuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY,
    payload: data
  };
};
export const updateLiaisonProcessApplicabilityStatusRemove_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    applicabilityStatus: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_APPLICABILITY_STATUS_REMOVE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "info"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectTaskID: "",
    processAccountableID: "",
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanExportSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanExportSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_EXPORT,
    payload: data
  };
};
export const getProjectZone_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_ZONE, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectZoneSuccess(data));
        } else {
          dispatch(getProjectZoneSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectZoneSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectZoneSuccess = (data) => {
  return {
    type: GET_PROJECT_ZONE,
    payload: data
  };
};
export const updateLiaisonTaskPlanReject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskStatusID: "4",
    updateRemark: "",
    rejectType: "",
    rejectionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_REJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const deleteBdLandDisput_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_BD_LAND_DISPUT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLegalTask_Export_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    issueTypeID: "",
    issueHandledBy: "",
    issueStatus: "",
    issueCategoryID: "",
    issuePriorityID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLegalTaskSuccessExport(data));
        } else {
          dispatch(getBdLegalTaskSuccessExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLegalTaskSuccessExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLegalTaskSuccessExport = (data) => {
  return {
    type: GET_BD_LEGAL_TASK_LIST_EXPORT,
    payload: data
  };
};
export const getBdLandDisputeExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    issueTypeID: "",
    issueHandledBy: "",
    issueStatus: "",
    issueCategoryID: "",
    issuePriorityID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_LAND_DISPUTE_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_LAND_DISPUTE_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_LAND_DISPUTE_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseActive_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    courtCaseID: "",
    courtCaseStatusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_ACTIVE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    caseOn: "",
    projectID: "",
    caseTypeID: "",
    caseHandledBy: "",
    caseStatus: "",
    caseJurisdiction: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseExportSuccess(data));
        } else {
          dispatch(getCourtCaseExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseExportSuccess = (data) => {
  return {
    type: GET_COURT_CASE_EXPORT,
    payload: data
  };
};
export const deleteCourtCase_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    disputeID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_COURT_CASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_COURT_CASE_PROJECT_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseProjectFilterSuccess(data));
        } else {
          dispatch(getCourtCaseProjectFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseProjectFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseProjectFilterSuccess = (data) => {
  return {
    type: GET_COURT_CASE_PROJECT_FILTER,
    payload: data
  };
};
export const getProjectDeedType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // "projectID":"",
    // "deedTypeID":"",
    // "deedStatus":"",
    // "generalSearch":"",
    // "sortOrder":"",
    // "iDisplayStart": 0,
    // "iDisplayLength": -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DEED, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDeedType(data));
        } else {
          dispatch(getProjectDeedType({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDeedType([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDeedType = (data) => {
  return {
    type: GET_PROJECT_DEED,
    payload: data
  };
};
export const createExtraLiaisonProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_EXTRA_LIAISON_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPhaseID: "",
    taskCategoryID: "",
    taskStatus: "",
    taskPriority: "",
    taskOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectTask_success(data));
        } else {
          dispatch(getBdProjectTask_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectTask_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTask_success = (data) => {
  return {
    type: GET_BD_PROJECT_TASK,
    payload: data
  };
};
export const createProjectTaskSupplementaryPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    deedTypeID: "",
    taskRemark: "",
    projectFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_TASK_SUPPLEMENTARY_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectConsolidatedReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_CONSOLIDATED_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectConsolidatedReportSuccess(data));
        } else {
          dispatch(getBdProjectConsolidatedReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectConsolidatedReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectConsolidatedReportSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_CONSOLIDATED_REPORT,
    payload: data
  };
};
export const getProjectFileAcquisitionStatusFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileAcquisitionStatusFilterSuccess(data));
        } else {
          dispatch(getProjectFileAcquisitionStatusFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileAcquisitionStatusFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileAcquisitionStatusFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER,
    payload: data
  };
};
export const updateProjectFileAcquisitionPendingRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectFileID: "",
    nextFollowUpDate: "",
    followUpRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_FILE_ACQUISTION_PENDING_REMARK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectFileAcquisitionOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectFileID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_FILE_ACQUISTION_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileAcquisitionPending_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    fileStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    acquisitionHandledBy: "",
    acquisitionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ACQUISITION_PENDING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_FILE_ACQUISITION_PENDING,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_FILE_ACQUISITION_PENDING,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_FILE_ACQUISITION_PENDING,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAcquiredFileExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    fileStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    acquisitionHandledBy: "",
    acquisitionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ACQUISITION_PENDING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAcquiredFileExportSuccess(data));
        } else {
          dispatch(getAcquiredFileExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAcquiredFileExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAcquiredFileExportSuccess = (data) => {
  return {
    type: GET_ACQUIRED_FILE_EXPORT,
    payload: data
  };
};
export const getFileAcquisitionInfoExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    acquisitionInfoID: "",
    projectFileID: "",
    villageID: "",
    projectID: "",
    infoStatus: "",
    generalSearch: "",
    acquisitionStatus: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_FILE_ACQUISITION_INFO, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFileAcquisitionInfoExportSuccess(data));
        } else {
          dispatch(getFileAcquisitionInfoExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFileAcquisitionInfoExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFileAcquisitionInfoExportSuccess = (data) => {
  return {
    type: GET_FILE_ACQUISITION_INFO_EXPORT,
    payload: data
  };
};
export const getProjectFileExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    fileStatus: "Active",
    acquisitionStatus: "1",
    acquisitionReasonID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileExportSuccess(data));
        } else {
          dispatch(getProjectFileExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileExportSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_EXPORT,
    payload: data
  };
};
export const getBdProjectConsolidatedReportPrint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    projectZoneID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectConsolidatedReportPrintSuccess(data));
        } else {
          dispatch(getBdProjectConsolidatedReportPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectConsolidatedReportPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectConsolidatedReportPrintSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT,
    payload: data
  };
};
export const getProjectFileAcquisitionPendingDetail_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL,
      data
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileAcquisitionPendingDetailSuccess(data));
        } else {
          dispatch(getProjectFileAcquisitionPendingDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileAcquisitionPendingDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileAcquisitionPendingDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL,
    payload: data
  };
};
export const getbdFileAcquisitionReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_FILE_ACQUISITION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getbdFileAcquisitionReasonSuccess(data));
        } else {
          dispatch(getbdFileAcquisitionReasonSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getbdFileAcquisitionReasonSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getbdFileAcquisitionReasonSuccess = (data) => {
  return {
    type: GET_BD_FILE_ACQUISITION_REASON,
    payload: data
  };
};
export const getProjectFileAcquisitionHandledByFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLegalOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_LEGAL_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectLegalOwnerFilterSuccess(data));
        } else {
          dispatch(getProjectLegalOwnerFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectLegalOwnerFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLegalOwnerFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_LEGAL_OWNER_FILTER,
    payload: data
  };
};
export const getProjectBdOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_BD_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdOwnerFilterSuccess(data));
        } else {
          dispatch(getProjectBdOwnerFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdOwnerFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdOwnerFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_BD_OWNER_FILTER,
    payload: data
  };
};
export const updateLiaisonProcessPlanOwners_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    processPlanOwnerID: "",
    ownerType: "Responsible",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_OWNERS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicabilityReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanApplicabilityReasonSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanApplicabilityReasonSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanApplicabilityReasonSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApplicabilityReasonSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON,
    payload: data
  };
};
export const getLoanPanel_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanPanelSuccess(data));
        } else {
          dispatch(getLoanPanelSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanPanelSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanPanelSuccess = (data) => {
  return {
    type: GET_LOAN_PANEL,
    payload: data
  };
};
export const getLoanApplicationEligibleProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_APPLICATION_ELIGIBLE_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanApplicationEligibleProjectSuccess(data));
        } else {
          dispatch(getLoanApplicationEligibleProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanApplicationEligibleProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanApplicationEligibleProjectSuccess = (data) => {
  return {
    type: GET_LOAN_APPLICATION_ELIGIBLE_PROJECT,
    payload: data
  };
};
export const getLoanApplicationEligibleProjectFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanApplicationEligibleProjectFileSuccess(data));
        } else {
          dispatch(getLoanApplicationEligibleProjectFileSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanApplicationEligibleProjectFileSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanApplicationEligibleProjectFileSuccess = (data) => {
  return {
    type: GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE,
    payload: data
  };
};
export const createLoanProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    appliedAmount: "",
    panelID: [],
    projectFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LOAN_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    panelID: "",
    bankID: "",
    processOwnerID: "",
    processStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PROCESS_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanSuccess(data));
        } else {
          dispatch(getLoanProcessPlanSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN,
    payload: data
  };
};
export const getLoanTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    taskOwnerID: "",
    taskStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanTaskPlanSuccess(data));
        } else {
          dispatch(getLoanTaskPlanSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanTaskPlanSuccess = (data) => {
  return {
    type: GET_LOAN_TASK_PLAN,
    payload: data
  };
};
export const updateLoanTaskPlanStart_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("documentRemark", data?.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPDATE_LOAN_TASK_PLAN_START,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanTaskPlanComplete_action = (data) => {
  let initialPayload;
  initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  for (var i = 0; i < data.taskDocuments.length; i++) {
    initialPayload.append(
      "documentFile" + data.taskDocuments[i].documentType + i,
      data.taskDocuments[i].documentFile
    );
  }
  initialPayload.append("taskDocuments", JSON.stringify(data.taskDocuments));
  initialPayload.append("documentRemark", data?.documentRemark);
  initialPayload.append("taskPlanID", data.taskPlanID);
  initialPayload.append("taskCompletedDate", data.taskCompletedDate);
  initialPayload.append("isSequenceExecution", data.isSequenceExecution);
  const config = data?.documentFile
    ? {
        headers: { "content-type": "multipart/form-data" }
      }
    : {};
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LOAN_PLAN_COMPLETE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_TASK_PLAN_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanTaskPlanDetailSuccess(data));
        } else {
          dispatch(getLoanTaskPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanTaskPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanTaskPlanDetailSuccess = (data) => {
  return {
    type: GET_LOAN_TASK_PLAN_DETAIL,
    payload: data
  };
};
export const updateProjectLaisonOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    liaisonOwnerID: "",
    projectRemark: "",
    liaisonOwnerChangeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_LIAISON_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLiaisonOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    liaisonOwnerID: "",
    projectStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_LIAISON_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectLiaisonOwnerFilterSuccess(data));
        } else {
          dispatch(getProjectLiaisonOwnerFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectLiaisonOwnerFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectLiaisonOwnerFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_LIAISON_OWNER_FILTER,
    payload: data
  };
};
export const updateLoanTaskPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    loanTaskPlanID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LOAN_TASK_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanProcessApplicabilityStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    applicabilityStatus: "",
    updateRemark: "",
    reasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LOAN_PROCESS_APPLICABILITY_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanTaskPlanApplicabilityStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    loanTaskPlanID: "",
    applicabilityStatus: "",
    changeRemark: "",
    // "reasonID":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LOAN_TASK_PLAN_APPLICABILITY_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanProcessPlanOwner_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ownerID: "",
    changeRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LOAN_PROCESS_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_PROCESS_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanDetailSuccess(data));
        } else {
          dispatch(getLoanProcessPlanDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanDetailSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN_DETAIL,
    payload: data
  };
};
export const getLoanProcessPlanProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    bankID: "",
    processOwnerID: "",
    processStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_PROCESS_PLAN_PROJECT_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanProjectFilterSuccess(data));
        } else {
          dispatch(getLoanProcessPlanProjectFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanProjectFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanProjectFilterSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN_PROJECT_FILTER,
    payload: data
  };
};
export const getLoanProcessPlanProcessFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    bankID: "",
    processOwnerID: "",
    processStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_PROCESS_PLAN_PROCESS_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanProcessFilterSuccess(data));
        } else {
          dispatch(getLoanProcessPlanProcessFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanProcessFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanProcessFilterSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN_PROCESS_FILTER,
    payload: data
  };
};
export const getLoanProcessPlanBankFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    bankID: "",
    processOwnerID: "",
    processStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_PROCESS_PLAN_BANK_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanBankFilterSuccess(data));
        } else {
          dispatch(getLoanProcessPlanBankFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanBankFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanBankFilterSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN_BANK_FILTER,
    payload: data
  };
};
export const getLoanProcessPlanOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    bankID: "",
    processOwnerID: "",
    processStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LOAN_PROCESS_PLAN_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessPlanOwnerFilterSuccess(data));
        } else {
          dispatch(getLoanProcessPlanOwnerFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessPlanOwnerFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessPlanOwnerFilterSuccess = (data) => {
  return {
    type: GET_LOAN_PROCESS_PLAN_OWNER_FILTER,
    payload: data
  };
};
export const getLiaisonProcessPlanDelayed_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_DELAYED,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanDelayedSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanDelayedSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanDelayedSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanDelayedSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_DELAYED,
    payload: data
  };
};
export const getLiaisonProcessPlanDelayedExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_DELAYED,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanDelayedExportSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanDelayedExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanDelayedExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanDelayedExportSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT,
    payload: data
  };
};
// BD Master
export const getBdLandDisputeTypeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    issueTypeID: "Dispute",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeTypeSuccessMaster(data));
        } else {
          dispatch(getBdLandDisputeTypeSuccessMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeTypeSuccessMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeTypeSuccessMaster = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_TYPE_MASTER,
    payload: data
  };
};
export const getBdLandDisputeTypeMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    issueTypeID: "Dispute",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "1",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeTypeSuccessMasterExport(data));
        } else {
          dispatch(getBdLandDisputeTypeSuccessMasterExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeTypeSuccessMasterExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeTypeSuccessMasterExport = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT,
    payload: data
  };
};
export const createLandIssueTypeMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLandIssueType_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landIssueTypeID: "",
    issueTypeName: "",
    issueType: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_CATEGORY_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_CATEGORY_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_CATEGORY_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLiaisonProcessCategoryMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_PROCESS_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // processCategoryID: "",
    // processCategoryName: "",
    // bdProjectTask: "",
    // statusID: "",
    // generalSearch: "",
    // sortOrder: "",
    // iDisplayStart: 0,
    // iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskCategoryMaster(data));
        } else {
          dispatch(getProjectTaskCategoryMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskCategoryMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskCategoryMaster = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_CATEGORY_MASTER,
    payload: data
  };
};
export const update_Bd_Project_Tasks_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectTaskID: "",
    ownerID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BD_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const CreateBdProjectTaskMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskName: "",
    taskCategoryID: "",
    taskPhaseID: "",
    taskCompletionType: "",
    isProjectMilestone: "",
    taskLeadTime: "",
    taskOrder: "",
    taskStepNo: "",
    taskOwnerID: "",
    taskPriority: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_BD_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const EditBdProjectTaskMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectTaskID: "",
    taskName: "",
    taskCategoryID: "",
    taskPhaseID: "",
    taskCompletionType: "",
    isProjectMilestone: "",
    taskLeadTime: "",
    taskStatus: "",
    taskOrder: "",
    taskStepNo: "",
    taskPriority: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_PROJECT_TASK_PLANE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdProjectTaskCategoryMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    categoryName: "",
    categoryOrder: "",
    categoryStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskDependencyMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskID: "",
    taskRelationShip: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_DEPENDENCY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: PROJECT_TASK_DEPENDENCY_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: PROJECT_TASK_DEPENDENCY_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: PROJECT_TASK_DEPENDENCY_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdProjectTaskDependencyMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskID: "",
    taskRelationship: "",
    dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_TASK_DEPENDENCY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectDependentTaskMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_DEPENDENT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: PROJECT_DEPENDENT_TASK_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: PROJECT_DEPENDENT_TASK_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: PROJECT_DEPENDENT_TASK_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskDependencyMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskDependencyID: "",
    taskID: "",
    taskRelationship: "",
    dependantTaskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_TASK_DEPENDENCY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPlanningAuthorityMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PLANNING_AUTHORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getPlanningAuthorityMaster(data));
        } else {
          dispatch(getPlanningAuthorityMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getPlanningAuthorityMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPlanningAuthorityMaster = (data) => {
  return {
    type: GET_PLANNING_AUTHORITY_MASTER,
    payload: data
  };
};
export const CreatePlanningAuthorityMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorityName: "",
    displayOrder: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PLANNING_AUTHORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdatePlanningAuthorityMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorityID: "",
    authorityName: "",
    displayOrder: "",
    authorityStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PLANNING_AUTHORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_CATEGORY_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_CATEGORY_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_CATEGORY_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLiaisonTaskCategoryMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskCategoryName: "",
    taskLeadTime: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskCategoryMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    taskCategoryName: "",
    taskLeadTime: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LIAISON_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateLiaisonTaskMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LIAISON_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const CreateLiaisonTaskMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LIAISON_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLiaisonProcessDependencyMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_PROCESS_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessDependencyMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // "projectID":"",
    // "deedTypeID":"",
    // "deedStatus":"",
    // "generalSearch":"",
    // "sortOrder":"",
    // "iDisplayStart": 0,
    // "iDisplayLength": -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessMasterSuccess(data));
        } else {
          dispatch(getLiaisonProcessMasterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessMasterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessMasterSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_MASTER,
    payload: data
  };
};
export const getLiaisonProcessCategoryMasterNew_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessCategoryMasterNewSuccess(data));
        } else {
          dispatch(getLiaisonProcessCategoryMasterNewSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessCategoryMasterNewSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessCategoryMasterNewSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW,
    payload: data
  };
};
export const createLiaisonProcessMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_PROCESS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          //toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBDLiasionProcessDetails_action = (data) => {
  console.log(data, "dataa");
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBDLiasionProcessDetails(data));
        } else {
          dispatch(getBDLiasionProcessDetails({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBDLiasionProcessDetails([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBDLiasionProcessDetails = (data) => {
  return {
    type: GET_LIAISON_PROCESS_DETAIL,
    payload: data
  };
};
export const splitProjectFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectFileID: "",
    fileRemark: "",
    splitFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.SPLIT_PROJECT_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterAccountable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectID: "",
    processID: "",
    processOwnerID: "",
    processStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterSyNumber_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    processOwnerID: "",
    processStatus: "",
    applicabilityStatus: "",
    acquisitionStatus: "",
    projectFileStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanFilterSyNumberSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanFilterSyNumberSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanFilterSyNumberSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanFilterSyNumberSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER,
    payload: data
  };
};
export const getLiaisonDocumentationTaskPlanFilterAccountable_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",
    projectID: "",
    projectFileID: "",
    taskStatus: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
            payload: data.data
          });
        } else {
          dispatch({
            type: GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanDocumentType_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    taskDocumentType: "",
    // "updateRemark":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIASON_PROCESS_DOCUMENT_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPlanApprovalReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    completionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "1",
    iDisplayLength: "20",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPlanApprovalReportSuccess(data));
        } else {
          dispatch(getLiaisonPlanApprovalReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPlanApprovalReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPlanApprovalReportSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT,
    payload: data
  };
};
export const getLiaisonPlanApprovalReportFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    completionStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPlanApprovalReportFilterProjectSuccess(data));
        } else {
          dispatch(getLiaisonPlanApprovalReportFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPlanApprovalReportFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPlanApprovalReportFilterProjectSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT,
    payload: data
  };
};
export const updateProjectTaskPlanApplicableStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskApplicableStatus: "",
    statusRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_APPLICABLE_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentMilestoneList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    milestoneStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PAYMENT_MILESTONE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_PAYMENT_MILESTONE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdPaymentMilestoneList_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_BD_PAYMENT_MILESTONE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdPaymentMilestoneList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_PAYMENT_MILESTONE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPlanApprovalReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    completionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPlanApprovalReportExportSuccess(data));
        } else {
          dispatch(getLiaisonPlanApprovalReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPlanApprovalReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPlanApprovalReportExportSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT,
    payload: data
  };
};
export const getProjectBdKPIReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    completionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "1",
    iDisplayLength: "20",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BD_KPI_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdKPIReportSuccess(data));
        } else {
          dispatch(getProjectBdKPIReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdKPIReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdKPIReportSuccess = (data) => {
  return {
    type: GET_PROJECT_BD_KPI_REPORT,
    payload: data
  };
};
export const getProjectBdKPIReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    completionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BD_KPI_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdKPIReportExportSuccess(data));
        } else {
          dispatch(getProjectBdKPIReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdKPIReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdKPIReportExportSuccess = (data) => {
  return {
    type: GET_PROJECT_BD_KPI_REPORT_EXPORT,
    payload: data
  };
};
export const getProjectMoUKPIReportFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    completionStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MOU_KPI_REPORT_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectMoUKPIReportFilterProjectSuccess(data));
        } else {
          dispatch(getProjectMoUKPIReportFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMoUKPIReportFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMoUKPIReportFilterProjectSuccess = (data) => {
  return {
    type: GET_PROJECT_MOU_KPI_REPORT_FILTER_PROJECT,
    payload: data
  };
};
export const projectMerger_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromProjectID: "",
    toProjectID: "",
    projectFileID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_MERGER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskRejectionReasonMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonID: "",
    status: "",
    reasonCategory: "All",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_REJECTION_REASON_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_REJECTION_REASON_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_REJECTION_REASON_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_REJECTION_REASON_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdProjectTaskRejectionReasonMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_TASK_REJECTION_REASON_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskRejectionReasonMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_REJECTION_REASON_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdAgreementTypeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    agreementTypeID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_AGREEMENT_TYPE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_AGREEMENT_TYPE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_AGREEMENT_TYPE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_AGREEMENT_TYPE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdAgreementTypeMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_AGREEMENT_TYPE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdAgreementTypeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_AGREEMENT_TYPE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApprovalExport_action = (data) => {
  const initialPayload = {
    fromDate: "",
    toDate: "",
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    taskApproverID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonDocumentationTaskPlanReject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskStatusID: "4",
    updateRemark: "",
    rejectionReasonID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_DOCUMENTATION_TASK_PLAN_REJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtEstablishment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_ESTABLISHMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_ESTABLISHMENT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_ESTABLISHMENT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_ESTABLISHMENT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCourtEstablishment_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_COURT_ESTABLISHMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtEstablishment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    establishmentName: "",
    establishmentAddress: "",
    establishmentStatus: "",
    establishmentId: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_ESTABLISHMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseStatusMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_COURT_CASE_STATUS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_CASE_STATUS_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_CASE_STATUS_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_CASE_STATUS_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCourtCaseStatusMaster_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_COURT_CASE_STATUS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseStatusMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // courtCaseStatusName: "",
    // caseStatus: "",
    // caseId: "",
    // generalSearch: "",
    // sortOrder: "",
    // iDisplayStart: "0",
    // iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_COURT_CASE_STATUS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseTypeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_TYPE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_CASE_TYPE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_CASE_TYPE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_CASE_TYPE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCourtCaseType_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_COURT_CASE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCourtCaseType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // courtCaseStatusName: "",
    // caseStatus: "",
    // caseId: "",
    // generalSearch: "",
    // sortOrder: "",
    // iDisplayStart: "0",
    // iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_COURT_CASE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationFeesMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_FEES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_REGISTRATION_FEES_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_REGISTRATION_FEES_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_REGISTRATION_FEES_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdRegistrationFees_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_REGISTRATION_FEES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdRegistrationFees_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_REGISTRATION_FEES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationFeesRateNew_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_FEES_RATE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationFeesRateNewSuccess(data));
        } else {
          dispatch(getBdRegistrationFeesRateNewSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationFeesRateNewSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationFeesRateNewSuccess = (data) => {
  return {
    type: GET_REGISTRATION_FEES_RATE_LIST,
    payload: data
  };
};
export const createBdRegistrationFeesRate_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_REGISTRATION_FEES_RATE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdRegistrationFeesRate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_REGISTRATION_FEES_RATE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const get_Liaison_Process_Dependency_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessDependency(data));
        } else {
          dispatch(getLiaisonProcessDependency({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessDependency([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessDependency = (data) => {
  return {
    type: GET_LIAISON_PROCESS_DEPENDENCY,
    payload: data
  };
};
export const getLiaisonProcessesException_Master_action = (data) => {
  console.log(data, "dataa");
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIASION_PROCESSES_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessesException(data));
        } else {
          dispatch(getLiaisonProcessesException({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessesException([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessesException = (data) => {
  return {
    type: GET_LIASION_PROCESSES_EXCEPTION,
    payload: data
  };
};
export const CreateLiaisonProcessesException_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIASION_PROCESSES_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateLiaisonProcessesException_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIASION_PROCESSES_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskList_action = (data) => {
  console.log(data, "dataa");
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskList(data));
        } else {
          dispatch(getApfTaskList({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskList([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskList = (data) => {
  return {
    type: GET_APF_TASK_LIST,
    payload: data
  };
};
export const createApfTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APF_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateApfTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const get_Apf_Panel_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_PANEL_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfPanelListMaster(data));
        } else {
          dispatch(getApfPanelListMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfPanelListMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfPanelListMaster = (data) => {
  return {
    type: GET_APF_PANEL_LIST,
    payload: data
  };
};
export const create_Apf_panel_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APF_PANEL_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const Update_Apf_panel_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_PANEL_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    menuID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getMasterSuccess(data));
        } else {
          dispatch(getMasterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getMasterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getMasterSuccess = (data) => {
  return {
    type: GET_MASTER,
    payload: data
  };
};
export const get_Project_Document_Type_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DOCUMENT_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentType(data));
        } else {
          dispatch(getProjectDocumentType({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentType([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentType = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_TYPE,
    payload: data
  };
};
export const Create_Project_Document_Type_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_DOCUMENT_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const Update_Project_Document_Type_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_DOCUMENT_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDelayReason_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIASISON_DELAY_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDelayReasonMaster(data));
        } else {
          dispatch(getLiaisonDelayReasonMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDelayReasonMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDelayReasonMaster = (data) => {
  return {
    type: GET_LIASISON_DELAY_REASON,
    payload: data
  };
};
export const Create_LiaisonDelayReason_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIASISON_DELAY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const Update_LiaisonDelayReason_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIASISON_DELAY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const get_Bank_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    bankStatus: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BANK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBankMaster(data));
        } else {
          dispatch(getBankMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBankMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBankMaster = (data) => {
  return {
    type: GET_BANK,
    payload: data
  };
};
export const Create_Bank_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_BANK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const Update_Bank_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BANK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const get_Bank_Category_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BANK_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBankCategoryMaster(data));
        } else {
          dispatch(getBankCategoryMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBankCategoryMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBankCategoryMaster = (data) => {
  return {
    type: GET_BANK_CATEGORY,
    payload: data
  };
};
export const get_Loan_Task_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanTask(data));
        } else {
          dispatch(getLoanTask({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanTask([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanTask = (data) => {
  return {
    type: GET_LOAN_TASK,
    payload: data
  };
};
export const get_Loan_Process_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLoanProcessMaster(data));
        } else {
          dispatch(getLoanProcessMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLoanProcessMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessMaster = (data) => {
  return {
    type: GET_LOAN_PROCESS,
    payload: data
  };
};
export const Create_Loan_Process_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LOAN_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const Update_Loan_Process_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LOAN_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
//
export const getLoanProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LOAN_PROCESS,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LOAN_PROCESS,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LOAN_PROCESS,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLoanProcess_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LOAN_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LOAN_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanPanelMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    loanPanelStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LOAN_PANEL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LOAN_PANEL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LOAN_PANEL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLoanPanel_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LOAN_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLoanPanel_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LOAN_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    paymentTypeID: "",
    projectID: "",
    processID: "",
    companyID: "",
    requestedByID: "",
    paymentStatusID: "",
    settlementStatusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PAYMENT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonPaymentExportSuccess(data));
        } else {
          dispatch(getLiaisonPaymentExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonPaymentExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonPaymentExportSuccess = (data) => {
  return {
    type: GET_LIAISON_PAYMENT_EXPORT,
    payload: data
  };
};
export const getBdProjectTaskTransaction_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_TRANSACTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_TRANSACTION,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_TRANSACTION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_TRANSACTION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdProjectTaskTransaction_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_TASK_TRANSACTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdProjectTaskTransaction_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_TRANSACTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createProjectDropReason_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROJECT_DROP_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectDropReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_DROP_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdTaskApprovalMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_TASK_APPROVAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_TASK_APPROVAL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_TASK_APPROVAL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_TASK_APPROVAL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createBdTaskApprovalMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_BD_TASK_APPROVAL, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateBdTaskApprovalMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BD_TASK_APPROVAL, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectListExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    priorityID: "",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectListExportSuccess(data));
        } else {
          dispatch(getProjectListExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectListExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectListExportSuccess = (data) => {
  return {
    type: GET_PROJECT_EXPORT,
    payload: data
  };
};
export const getSubRegistrarOfficeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SUB_REGISTRAR_OFFICE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_SUB_REGISTRAR_OFFICE,
            payload: data
          });
        } else {
          dispatch({
            type: GET_SUB_REGISTRAR_OFFICE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_SUB_REGISTRAR_OFFICE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const CreateSubRegistrarOfficeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_SUB_REGISTRAR_OFFICE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateSubRegistrarOfficeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_SUB_REGISTRAR_OFFICE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSubRegistrarMappingMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SUB_REGISTRAR_MAPPING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_SUB_REGISTRAR_MAPPING,
            payload: data
          });
        } else {
          dispatch({
            type: GET_SUB_REGISTRAR_MAPPING,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_SUB_REGISTRAR_MAPPING,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createSubRegistrarMappingMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_SUB_REGISTRAR_MAPPING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateSubRegistrarMappingMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_SUB_REGISTRAR_MAPPING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationFeesRateMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_FEES_RATE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_REGISTRATION_FEES_RATE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_REGISTRATION_FEES_RATE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_REGISTRATION_FEES_RATE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessApplicabilityReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_APPLICABILITY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_APPLICABILITY_REASON,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_APPLICABILITY_REASON,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_APPLICABILITY_REASON,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLiaisonProcessApplicabilityReason_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_PROCESS_APPLICABILITY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessApplicabilityReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_APPLICABILITY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLandLordMeeting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    meetingStageID: "",
    meetingDate: "",
    landLordName: "",
    contactNumber: "",
    consultantName: "",
    attendeeFromLandlord: "",
    // "stageID":"",
    remark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_LANLORD_MEETING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLoadMeeting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    fromDate: "",
    toDate: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LANDLORD_MEETING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLoadMeetingSuccess(data));
        } else {
          dispatch(getLandLoadMeetingSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLoadMeetingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLoadMeetingSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING,
    payload: data
  };
};
export const getLandLoadMeetingStage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LANDLORD_MEETING_STAGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLoadMeetingStageSuccess(data));
        } else {
          dispatch(getLandLoadMeetingStageSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLoadMeetingStageSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLoadMeetingStageSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING_STAGE,
    payload: data
  };
};
export const getLandLoadMeetingParticular_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LANDLORD_MEETING_PARTICULAR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLoadMeetingParticularSuccess(data));
        } else {
          dispatch(getLandLoadMeetingParticularSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLoadMeetingParticularSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLoadMeetingParticularSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING_PARTICULAR,
    payload: data
  };
};
export const getNewsLetterCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_NEWS_LETTER_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getNewsLetterCategorySuccess(data));
        } else {
          dispatch(getNewsLetterCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getNewsLetterCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getNewsLetterCategorySuccess = (data) => {
  return {
    type: GET_NEWS_LETTER_CATEGORY,
    payload: data
  };
};
export const getLiaisonDelayReasonExport_Master_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    referenceType: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIASISON_DELAY_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonDelayReasonMasterExport(data));
        } else {
          dispatch(getLiaisonDelayReasonMasterExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonDelayReasonMasterExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDelayReasonMasterExport = (data) => {
  return {
    type: GET_LIASISON_DELAY_REASON_MASTER_EXPORT,
    payload: data
  };
};
export const getProjectDocumentExportTypeMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    referenceType: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DOCUMENT_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentTypeExport(data));
        } else {
          dispatch(getProjectDocumentTypeExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentTypeExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentTypeExport = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_TYPE_EXPORT,
    payload: data
  };
};
export const getBankMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BANK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BANK_MASTER_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BANK_MASTER_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BANK_MASTER_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfPanelExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_PANEL_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_PANEL_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_PANEL_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_PANEL_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanProcessExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LOAN_PROCESS_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LOAN_PROCESS_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LOAN_PROCESS_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanPanelExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_PANEL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LOAN_PANEL_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LOAN_PANEL_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LOAN_PANEL_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_TASK_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_TASK_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_TASK_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_PROCESS_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_MASTER_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_MASTER_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_MASTER_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectTaskMasterExport_success(data));
        } else {
          dispatch(getBdProjectTaskMasterExport_success({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectTaskMasterExport_success([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskMasterExport_success = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_MASTER_EXPORT,
    payload: data
  };
};
export const getApfTaskPlanReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    taskStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanReportSuccess(data));
        } else {
          dispatch(getApfTaskPlanReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanReportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_REPORT,
    payload: data
  };
};
export const getBdRegistrationFeesRateMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_FEES_RATE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_REGISTRATION_FEES_RATE_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_REGISTRATION_FEES_RATE_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_REGISTRATION_FEES_RATE_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdAgreementTypeMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_AGREEMENT_TYPE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_AGREEMENT_TYPE_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_AGREEMENT_TYPE_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_AGREEMENT_TYPE_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessesExceptionExport_Master_action = (data) => {
  console.log(data, "dataa");
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIASION_PROCESSES_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessesExceptionExport(data));
        } else {
          dispatch(getLiaisonProcessesExceptionExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessesExceptionExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessesExceptionExport = (data) => {
  return {
    type: GET_LIASION_PROCESSES_EXCEPTION_EXPORT,
    payload: data
  };
};
export const getBdProjectTaskRejectionReasonExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // reasonID:"",
    // status: "",
    // generalSearch: "",
    // sortOrder: "",
    // iDisplayStart: 0,
    // iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_REJECTION_REASON_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdPaymentMilestoneListExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PAYMENT_MILESTONE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationFeesExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_FEES, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_REGISTRATION_FEES_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_REGISTRATION_FEES_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_REGISTRATION_FEES_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPlanningAuthorityExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PLANNING_AUTHORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getPlanningAuthorityExportMaster(data));
        } else {
          dispatch(getPlanningAuthorityExportMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getPlanningAuthorityExportMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPlanningAuthorityExportMaster = (data) => {
  return {
    type: GET_PLANNING_AUTHORITY_EXPORT_MASTER,
    payload: data
  };
};
export const getBdProjectTaskDependencyExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationShip: "",
    //   generalSearch: "",
    //   sortOrder: "",
    //   iDisplayStart: "0",
    //   iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_DEPENDENCY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: PROJECT_TASK_DEPENDENCY_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: PROJECT_TASK_DEPENDENCY_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: PROJECT_TASK_DEPENDENCY_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskCategoryExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_TASK_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskCategoryExportMaster(data));
        } else {
          dispatch(getProjectTaskCategoryExportMaster({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskCategoryExportMaster([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskCategoryExportMaster = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER,
    payload: data
  };
};
export const getBdTaskApprovalExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_TASK_APPROVAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_TASK_EXPORT_APPROVAL,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_TASK_EXPORT_APPROVAL,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_TASK_EXPORT_APPROVAL,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDropReasonExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DROP_REASON, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDropReasonExportSuccess(data));
        } else {
          dispatch(getProjectDropReasonExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDropReasonExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDropReasonExportSuccess = (data) => {
  return {
    type: GET_PROJECT_DROP_REASON_EXPORT_LIST,
    payload: data
  };
};
export const getBdProjectTaskTransactionExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_TRANSACTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_TASK_TRANSACTION_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_TASK_TRANSACTION_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_TASK_TRANSACTION_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSubRegistrarMappingExportMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SUB_REGISTRAR_MAPPING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_SUB_REGISTRAR_MAPPING_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_SUB_REGISTRAR_MAPPING_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_SUB_REGISTRAR_MAPPING_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createProjectZone_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    zoneName: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROJECT_ZONE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateProjectZone_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    zoneName: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_ZONE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLoanTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LOAN_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LOAN_TASK_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LOAN_TASK_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LOAN_TASK_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonTaskCategoryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LIAISON_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_CATEGORY_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_CATEGORY_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_CATEGORY_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseTypeExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_CASE_TYPE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_CASE_TYPE_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_CASE_TYPE_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_CASE_TYPE_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseStatusExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_COURT_CASE_STATUS_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_CASE_STATUS_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_CASE_STATUS_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_CASE_STATUS_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseEstablishmentExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COURT_ESTABLISHMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COURT_ESTABLISHMENT_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COURT_ESTABLISHMENT_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COURT_ESTABLISHMENT_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeTypeExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_LAND_DISPUTE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_LAND_DISPUTE_TYPE_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_LAND_DISPUTE_TYPE_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_LAND_DISPUTE_TYPE_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentTypeExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DOCUMENT_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_DOCUMENT_TYPE_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_DOCUMENT_TYPE_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_DOCUMENT_TYPE_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessApplicabilityReasonExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_APPLICABILITY_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessDependencyExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_DEPENDENCY_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_DEPENDENCY_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_DEPENDENCY_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessCategoryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_CATEGORY_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_CATEGORY_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_CATEGORY_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_TASK_LIST_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_TASK_LIST_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_TASK_LIST_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const addLandLordMeetingParticularRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    particularID: "",
    remark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LAND_LORD_MEETING_PARTICULAR_REMARK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingParticularRemark_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    particularID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_PARTICULAR_REMARK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingParticularRemark(data));
        } else {
          dispatch(getLandLordMeetingParticularRemark({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingParticularRemark([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingParticularRemark = (data) => {
  return {
    type: GET_LAND_LORD_MEETING_PARTICULAR_REMARK,
    payload: data
  };
};
export const getLandLordMeetingAttendee_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_ATTENDEE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingAttendee(data));
        } else {
          dispatch(getLandLordMeetingAttendee({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingAttendee([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingAttendee = (data) => {
  return {
    type: GET_LAND_LORD_MEETING_ATTENDEE,
    payload: data
  };
};
export const getLandlordMeetingParticular_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_PARTICULAR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_PARTICULAR,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_PARTICULAR,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_PARTICULAR,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const createLandlordMeetingParticular_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    //   taskID: "",
    //   taskRelationship: "",
    //   dependantTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LAND_LORD_MEETING_PARTICULAR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLandlordMeetingParticular_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // courtCaseStatusName: "",
    // caseStatus: "",
    // caseId: "",
    // generalSearch: "",
    // sortOrder: "",
    // iDisplayStart: "0",
    // iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LAND_LORD_MEETING_PARTICULAR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandlordMeetingParticularExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_PARTICULAR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_PARTICULAR_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_PARTICULAR_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_PARTICULAR_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingStage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_STAGE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_STAGE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingStageExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_STAGE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const CreateLandLordMeetingStage_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LAND_LORD_MEETING_STAGE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const UpdateLandLordMeetingStage_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LAND_LORD_MEETING_STAGE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessPlanRemark_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    liaisonProcessPlanID: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_REMARK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateLandLordMeeting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // $meetingID = $input->meetingID;
    // $landlordName = $input->landlordName;
    // $contactNumber = $input->contactNumber;
    // $consultantName = $input->consultantName;
    // $scheduledDate = $input->scheduledDate;
    // $attendeeFromLandlord = $input->attendeeFromLandlord;
    // $stageID = $input->stageID;
    // $meetingRemark = $input->remark;
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_LANDLORD_MEETING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLandLordMeetingDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LANDLORD_MEETING_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingDetail(data));
        } else {
          dispatch(getLandLordMeetingDetail({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingDetail([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingDetail = (data) => {
  return {
    type: GET_LANDLORD_MEETING_DETAIL,
    payload: data
  };
};

export const getLandLordMeetingStageMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_STAGE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLandLordMeetingStageMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_LORD_MEETING_STAGE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_MASTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LAND_LORD_MEETING_STAGE_MASTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LAND_LORD_MEETING_STAGE_MASTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getApfTaskPlanReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    taskStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanReportExportSuccess(data));
        } else {
          dispatch(getApfTaskPlanReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanReportExportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_REPORT_EXPORT,
    payload: data
  };
};

export const getApfTaskPlanExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    panelID: "",
    taskID: "",
    projectFileID: "",
    taskOwnerID: "",
    taskAccountableID: "",
    taskStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanExportSuccess(data));
        } else {
          dispatch(getApfTaskPlanExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanExportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_EXPORT,
    payload: data
  };
};

export const getApfTaskPlanQueryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectID: "",
    projectFileID: "",
    taskID: "",
    panelID: "",
    queryOwnerID: "",
    queryAccountableID: "",
    queryStatus: "",
    fromDate: "",
    toDate: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_QUERY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryExportSuccess(data));
        } else {
          dispatch(getApfTaskPlanQueryExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryExportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_EXPORT,
    payload: data
  };
};

export const addLandLordMeetingAttendee_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    attendeeID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LAND_LORD_MEETING_ATTENDEE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const addLandLordMeetingMom_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    momDescription: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.ADD_LAND_LORD_MEETING_MOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLandLordMeetingMom_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_LORD_MEETING_MOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingMom(data));
        } else {
          dispatch(getLandLordMeetingMom({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingMom([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingMom = (data) => {
  return {
    type: GET_LAND_LORD_MEETING_MOM,
    payload: data
  };
};

export const getLiaisonProcessDependentMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_DEPENDENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_DEPENDENT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_DEPENDENT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_DEPENDENT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateLandlordMeetingStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    meetingRemark: "",
    meetingStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LAND_LORD_MEETING_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateProjectFileMouDate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_FILE_MOU_DATE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdProjectDeedType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_PROJECT_DEED_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_BD_PROJECT_DEED_TYPE,
            payload: data
          });
        } else {
          dispatch({
            type: GET_BD_PROJECT_DEED_TYPE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_BD_PROJECT_DEED_TYPE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const liaisonProcessPlanMerger_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentProcessPlanID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_LIAISON_PROCESS_PLAN_MERGER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLiaisonProcessPlanMergerFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_MERGER_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanMergerFileSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanMergerFileSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanMergerFileSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanMergerFileSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_MERGER_FILE,
    payload: data
  };
};

export const importProjectFileMouDate_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_PROJECT_FILE_MOU_DATE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdLandDisputePriority_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_LAND_DISPUTE_PRIORITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputePrioritySuccess(data));
        } else {
          dispatch(getBdLandDisputePrioritySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputePrioritySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputePrioritySuccess = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_PRIORITY,
    payload: data
  };
};

export const getApfTaskPlanQueryTemplete_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_TEMPLETE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_TASK_PLAN_QUERY_TEMPLETE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const importApfTaskPlanQuery_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);
  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_APF_TASK_PLAN_QUERY,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateBdLandDispute_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    landDisputeID: "",
    projectFileID: "",
    issueTypeID: "",
    issuePriorityID: "",
    issueDescription: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BD_LAND_DISPUTE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createLiaisonProcessPlanDependency_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_LIAISON_PROCESS_PLAN_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLiaisonProcessPlanDependencyException_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanDependencyExceptionSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanDependencyExceptionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanDependencyExceptionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanDependencyExceptionSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_EXCEPTION,
    payload: data
  };
};

export const updateLiaisonProcessPlanDependencyException_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLAN_DEPENDENCY_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectTaskPlanApprovalFilterApprovalBy_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskOwnerID: "",
    taskStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskPlanApprovalFilterApprovalBySuccess(data));
        } else {
          dispatch(getProjectTaskPlanApprovalFilterApprovalBySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskPlanApprovalFilterApprovalBySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanApprovalFilterApprovalBySuccess = (data) => {
  return {
    type: GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY,
    payload: data
  };
};

export const getLiaisonProcessPlanApprovalFilterApprover_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    completionStatus: "",
    processOwnerID: "",
    isAdmin: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanApprovalFilterApproverSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanApprovalFilterApproverSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanApprovalFilterApproverSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanApprovalFilterApproverSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY,
    payload: data
  };
};

export const getLiaisonDocumentationTaskPlanApprovalFilterApprover_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    documentTaskPlanID: "",
    projectID: "",
    projectFileID: "",
    fromDate: "",
    toDate: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            getLiaisonDocumentationTaskPlanApprovalFilterApproverSuccess(data)
          );
        } else {
          dispatch(
            getLiaisonDocumentationTaskPlanApprovalFilterApproverSuccess({})
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(
          getLiaisonDocumentationTaskPlanApprovalFilterApproverSuccess([])
        );
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApprovalFilterApproverSuccess = (
  data
) => {
  return {
    type: GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER,
    payload: data
  };
};

export const updateProjectPhase_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPhaseID: "",
    updateRemark: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_PHASE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_PLAN_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanFile(data));
        } else {
          dispatch(getApfTaskPlanFile({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanFile([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanFile = (data) => {
  return {
    type: GET_APF_TASK_PLAN_FILE,
    payload: data
  };
};

export const getLandLordMeetingDetailPrint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    meetingID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LANDLORD_MEETING_DETAIL_PRINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingDetailPrintSuccess(data));
        } else {
          dispatch(getLandLordMeetingDetailPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingDetailPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingDetailPrintSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING_DETAIL_PRINT,
    payload: data
  };
};

export const rescheduleLandLordMeeting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.RESCHEDULE_LANDLORD_MEETING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getApfTaskPlanQueryFilterFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_FILTER_FILE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryFilterFile(data));
        } else {
          dispatch(getApfTaskPlanQueryFilterFile({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryFilterFile([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryFilterFile = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_FILTER_FILE,
    payload: data
  };
};

export const getLiaisonProcessAuthorityMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    liaisonProcessID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_AUTHORITY_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessAuthorityMapSuccess(data));
        } else {
          dispatch(getLiaisonProcessAuthorityMapSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessAuthorityMapSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessAuthorityMapSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_AUTHORITY_MAP,
    payload: data
  };
};

export const updateLiaisonProcessPlanningAuthority_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    planningAuthorityID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_PLANNING_AUTHORITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getApfTaskPlanQueryResponsible_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryResponsible(data));
        } else {
          dispatch(getApfTaskPlanQueryResponsible({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryResponsible([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryResponsible = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE,
    payload: data
  };
};

export const getApfTaskPlanQueryFilterAccountable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryFilterAccountable(data));
        } else {
          dispatch(getApfTaskPlanQueryFilterAccountable({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryFilterAccountable([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryFilterAccountable = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE,
    payload: data
  };
};
export const getApfTaskPlanOwnerFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_OWNER_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_TASK_PLAN_OWNER_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_TASK_PLAN_OWNER_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_TASK_PLAN_OWNER_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getApfTaskPlanAccountableFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
            payload: data
          });
        } else {
          dispatch({
            type: GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectZoneExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_ZONE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectZoneExportSuccess(data));
        } else {
          dispatch(getProjectZoneExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectZoneExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectZoneExportSuccess = (data) => {
  return {
    type: GET_PROJECT_ZONE_EXPORT,
    payload: data
  };
};

export const getLandlordMeetingExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_LAND_LORD_MEETING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandlordMeetingExportSuccess(data));
        } else {
          dispatch(getLandlordMeetingExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandlordMeetingExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandlordMeetingExportSuccess = (data) => {
  return {
    type: GET_LAND_LORD_MEETING_EXPORT,
    payload: data
  };
};

export const getLiaisonProcessForException_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_FOR_EXCEPTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIAISON_PROCESS_FOR_EXCEPTION,
            payload: data
          });
        } else {
          dispatch({
            type: GET_LIAISON_PROCESS_FOR_EXCEPTION,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIAISON_PROCESS_FOR_EXCEPTION,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLandLoadMeetingReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    fromDate: "",
    toDate: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LANDLORD_MEETING_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingReportSuccess(data));
        } else {
          dispatch(getLandLordMeetingReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingReportSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING_REPORT,
    payload: data
  };
};

export const getLandLordMeetingReportDetailPrint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    fromDate: "",
    toDate: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandLordMeetingReportDetailPrintSuccess(data));
        } else {
          dispatch(getLandLordMeetingReportDetailPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandLordMeetingReportDetailPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandLordMeetingReportDetailPrintSuccess = (data) => {
  return {
    type: GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT,
    payload: data
  };
};
export const getProjectFileFilterForTemplate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    villageID: "",
    fileStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_FILE_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch({
            type: GET_PROJECT_FILE_FILTER_FOR_TEMPLATE,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_FILE_FILTER_FOR_TEMPLATE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_FILE_FILTER_FOR_TEMPLATE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_CATEGORY,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_CATEGORY,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_CATEGORY,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const createProjectCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROJECT_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const UpdateProjectCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdProjectTaskProjectCategoryMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectTaskProjectCategoryMapSuccess(data));
        } else {
          dispatch(getBdProjectTaskProjectCategoryMapSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectTaskProjectCategoryMapSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectTaskProjectCategoryMapSuccess = (data) => {
  return {
    type: GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP,
    payload: data
  };
};

export const updateBdProjectTaskProjectCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectCategoryID: [],
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectCategoryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_PROJECT_CATEGORY_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_PROJECT_CATEGORY_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_PROJECT_CATEGORY_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectFileFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPhaseID: "",
    acquisitionStatus: "",
    acquisitionReasonID: "",
    projectStatus: "Active",
    fileStatus: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_FILE_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileFilterProjectSuccess(data));
        } else {
          dispatch(getProjectFileFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFileFilterProjectSuccess = (data) => {
  return {
    type: GET_PROJECT_FILE_FILTER_PROJECT,
    payload: data
  };
};

export const getProjectPriority_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_PRIORITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectPrioritySuccess(data));
        } else {
          dispatch(getProjectPrioritySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectPrioritySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPrioritySuccess = (data) => {
  return {
    type: GET_PROJECT_PRIORITY,
    payload: data
  };
};

export const getLandAcquisitionKpiReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "Active",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_KPI_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionKpiReportSuccess(data));
        } else {
          dispatch(getLandAcquisitionKpiReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionKpiReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionKpiReportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_KPI_REPORT,
    payload: data
  };
};

export const getLandAcquisitionKpiReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    completionStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_KPI_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionKpiReportExportSuccess(data));
        } else {
          dispatch(getLandAcquisitionKpiReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionKpiReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionKpiReportExportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_KPI_REPORT_EXPORT,
    payload: data
  };
};

//   sada
export const getProjectMoUKPIReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",

    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_MOU_KPI_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectMoUKPIReportSuccess(data));
        } else {
          dispatch(getProjectMoUKPIReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMoUKPIReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMoUKPIReportSuccess = (data) => {
  return {
    type: GET_PROJECT_MOU_KPI_REPORT,
    payload: data
  };
};

export const getProjectMoUKPIReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    statusID: "",

    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_MOU_KPI_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectMoUKPIReportExportSuccess(data));
        } else {
          dispatch(getProjectMoUKPIReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMoUKPIReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMoUKPIReportExportSuccess = (data) => {
  return {
    type: GET_PROJECT_MOU_KPI_REPORT_EXPORT,
    payload: data
  };
};

export const getLandAcquisitionReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    processOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionReportSuccess(data));
        } else {
          dispatch(getLandAcquisitionReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionReportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_REPORT,
    payload: data
  };
};

export const getLandAcquisitionReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    //   projectID: "",
    //   processOwnerID: "",
    //   processID: "",
    //   generalSearch: "",
    //   sortOrder: "",
    //   iDisplayStart: "0",
    //   iDisplayLength: "-1",

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionReportExportSuccess(data));
        } else {
          dispatch(getLandAcquisitionReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionReportExportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_REPORT_EXPORT,
    payload: data
  };
};

export const getApfTaskPlanQueryReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    panelID: "",
    queryOwnerID: "",
    queryAccountableID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryReportSuccess(data));
        } else {
          dispatch(getApfTaskPlanQueryReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryReportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_REPORT,
    payload: data
  };
};

export const getApfTaskPlanQueryReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    panelID: "",
    queryOwnerID: "",
    queryAccountableID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_APF_TASK_PLAN_QUERY_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanQueryReportExportSuccess(data));
        } else {
          dispatch(getApfTaskPlanQueryReportExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanQueryReportExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanQueryReportExportSuccess = (data) => {
  return {
    type: GET_APF_TASK_PLAN_QUERY_REPORT_EXPORT,
    payload: data
  };
};
export const getProjectPhaseMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_PHASE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectPhaseMasterSuccess(data));
        } else {
          dispatch(getProjectPhaseMasterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectPhaseMasterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPhaseMasterSuccess = (data) => {
  return {
    type: GET_PROJECT_PHASE_MASTER,
    payload: data
  };
};

export const getProjectPhaseMasterExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_PHASE_MASTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectPhaseMasterExportSuccess(data));
        } else {
          dispatch(getProjectPhaseMasterExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectPhaseMasterExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectPhaseMasterExportSuccess = (data) => {
  return {
    type: GET_PROJECT_PHASE_MASTER_EXPORT,
    payload: data
  };
};
export const createProjectPhaseMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_PHASE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateProjectPhaseMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_PHASE_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionKPIReportItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processOwnerID: "",
    processID: "",
    categoryID: "",
    completionType: "",

    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_REPORT_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionReportItemtSuccess(data));
        } else {
          dispatch(getLandAcquisitionReportItemtSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionReportItemtSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionReportItemtSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_REPORT_ITEM,
    payload: data
  };
};

export const getLandAcquisitionKPIItemReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_REPORT_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAcquisitionKPIReportItemExportSuccess(data));
        } else {
          dispatch(getLandAcquisitionKPIReportItemExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAcquisitionKPIReportItemExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAcquisitionKPIReportItemExportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_REPORT_ITEM_EXPORT,
    payload: data
  };
};

//   ---------------

export const getPoRejectionReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    projectID: "",
    sortOrder: "",
    departmentID: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PO_REJECTION_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getPoRejectionReportSuccess(data));
        } else {
          dispatch(getPoRejectionReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getPoRejectionReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPoRejectionReportSuccess = (data) => {
  return {
    type: GET_PO_REJECTION_REPORT,
    payload: data
  };
};

export const getPoRejectionReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    projectID: "",
    sortOrder: "",
    departmentID: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PO_REJECTION_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          return data?.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        return [];
      });
  };
};

export const getPoCycleReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    projectID: "",
    fromDate: "",
    toDate: "",
    sortOrder: "",
    departmentID: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PO_CYCLE_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getPoCycleReportSuccess(data));
        } else {
          dispatch(getPoCycleReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getPoCycleReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getPoCycleReportSuccess = (data) => {
  return {
    type: GET_PO_CYCLE_REPORT,
    payload: data
  };
};
export const getPoCycleReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    projectID: "",
    fromDate: "",
    toDate: "",
    sortOrder: "",
    departmentID: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PO_CYCLE_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          return data?.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        return [];
        dispatch(mainLoadingFalse());
      });
  };
};

export const getStockProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    projectID: "",
    statusID: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_STOCK_PROJECT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getStockProjectSuccess(data));
        } else {
          dispatch(getStockProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getStockProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getStockProjectSuccess = (data) => {
  return {
    type: GET_STOCK_PROJECT,
    payload: data
  };
};
export const getLandAquisationOveralReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    categoryID: "",
    taskOwnerID: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_OVERALL_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLandAquisationOveralReportSuccess(data));
        } else {
          dispatch(getLandAquisationOveralReportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLandAquisationOveralReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLandAquisationOveralReportSuccess = (data) => {
  return {
    type: GET_LAND_ACQUISITION_OVERALL_REPORT,
    payload: data
  };
};

export const getLandAquisationOveralReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    taskID: "",
    categoryID: "",
    taskOwnerID: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    return ErpApiService.post(
      ErpApi.GET_LAND_ACQUISITION_OVERALL_REPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        if (!data.error) {
          return data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        return [];
      });
  };
};

export const projectSplit_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectFileID: [],
    projectID: "",
    newProjectName: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_SPLIT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonProcessTaskPlanOrder_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_PROCESS_TASK_PLAN_ORDER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const changeProjectTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CHANGE_PROJECT_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdTaskApprovalFilterUser_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskID: "",
    authorisedUserID: "",
    authorityType: "",
    status: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_TASK_APPROVAL_FILTER_USER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getBdTaskApprovalFilterUserSuccess(data));
        } else {
          dispatch(getBdTaskApprovalFilterUserSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdTaskApprovalFilterUserSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdTaskApprovalFilterUserSuccess = (data) => {
  return {
    type: GET_BD_TASK_APPROVAL_FILTER_USER,
    payload: data
  };
};
export const getBdTaskApprovalFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskID: "",
    authorisedUserID: "",
    authorityType: "",
    status: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_TASK_APPROVAL_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getBdTaskApprovalFilterTaskSuccess(data));
        } else {
          dispatch(getBdTaskApprovalFilterTaskSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdTaskApprovalFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdTaskApprovalFilterTaskSuccess = (data) => {
  return {
    type: GET_BD_TASK_APPROVAL_FILTER_TASK,
    payload: data
  };
};
export const getBdTaskApprovalTaskToAdd_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    authorisedUserID: "",
    authorityType: "",
    status: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_TASK_APPROVAL_TASK_TO_ADD,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getBdTaskApprovalTaskToAddSuccess(data));
        } else {
          dispatch(getBdTaskApprovalTaskToAddSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdTaskApprovalTaskToAddSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdTaskApprovalTaskToAddSuccess = (data) => {
  return {
    type: GET_BD_TASK_APPROVAL_TASK_TO_ADD,
    payload: data
  };
};

export const updateProjectTaskPlanOwner_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_TASK_PLAN_OWNER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectTaskForOwnerUpdate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskID: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_FOR_OWNER_UPDATE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectTaskForOwnerUpdate(data));
        } else {
          dispatch(getProjectTaskForOwnerUpdate([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskForOwnerUpdate([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectTaskForOwnerUpdate = (data) => {
  return {
    type: GET_PROJECT_TASK_FOR_OWNER_UPDATE,
    payload: data
  };
};

export const getProjectDroppedFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPhaseID: "",
    planningAuthorityID: "",
    projectStatus: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DROPPED_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectDroppedFilter(data));
        } else {
          dispatch(getProjectDroppedFilter([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDroppedFilter([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectDroppedFilter = (data) => {
  return {
    type: GET_PROJECT_DROPPED_FILTER,
    payload: data
  };
};
export const updateApfTaskPlanApplication_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_APF_TASK_PLAN_APPLICATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateLiaisonTaskPlanApplicability_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_LIAISON_TASK_PLAN_APPLICABILITY_STATUS_BULK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonDocumentationTaskPlanApplicable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    accountableID: "",
    projectID: "",
    projectFileID: "",
    processPlanID: "",
    taskPlanStatus: "",
    taskOwnerID: "",
    categoryID: "",
    documentType: "",
    applicableStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.LIASON_DOCUMENT_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE,
            payload: { ...data, initialPayload: initialPayload }
          });
        } else {
          dispatch({
            type: GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFlowListExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    applicableStatus: "",
    parentID: "0",
    ownershipID: "",
    generalSearch: "",
    sortOrder: "",
    sortParameter: "",
    ...data
    // taskOwnerID : LoginuserId
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_TASK_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFlowListSuccessExport(data));
        } else {
          dispatch(getProjectFlowListSuccessExport({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFlowListSuccessExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectFlowListSuccessExport = (data) => {
  return {
    type: GET_PROJECT_FLOW_LIST_EXPORT,
    payload: data
  };
};
export const getApfTaskPlanExtraFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APF_TASK_EXTRA_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getApfTaskPlanExtraFile(data));
        } else {
          dispatch(getApfTaskPlanExtraFile({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getApfTaskPlanExtraFile([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getApfTaskPlanExtraFile = (data) => {
  return {
    type: GET_APF_TASK_EXTRA_FILE,
    payload: data
  };
};

export const updateApfTaskPlanFile_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APF_TASK_EXTRA_FILE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getSubMenu_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_SUB_MENU, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getSubMenu(data));
        } else {
          dispatch(getSubMenu({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getSubMenu([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getSubMenu = (data) => {
  return {
    type: GET_SUB_MENU,
    payload: data
  };
};

export const getProjectDocumentRepository_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositorySuccess(data));
        } else {
          dispatch(getProjectDocumentRepositorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositorySuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY,
    payload: data
  };
};
export const getProjectDocumentRepositoryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositoryExportSuccess(data));
        } else {
          dispatch(getProjectDocumentRepositoryExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositoryExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositoryExportSuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY_EXPORT,
    payload: data
  };
};
export const getProjectDocumentRepositoryProjectFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositoryProjectFilterSuccess(data));
        } else {
          dispatch(getProjectDocumentRepositoryProjectFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositoryProjectFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositoryProjectFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER,
    payload: data
  };
};
export const getProjectDocumentRepositoryProjectFileFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositoryProjectFileFilterSuccess(data));
        } else {
          dispatch(getProjectDocumentRepositoryProjectFileFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositoryProjectFileFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositoryProjectFileFilterSuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER,
    payload: data
  };
};
export const getProjectDocumentRepositoryFilterDocumentType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectFileID: "",
    deedTypeID: "",
    documentTypeID: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositoryFilterDocumentTypeSuccess(data));
        } else {
          dispatch(getProjectDocumentRepositoryFilterDocumentTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositoryFilterDocumentTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositoryFilterDocumentTypeSuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE,
    payload: data
  };
};

export const fileDownload_action = (url, { isMainLoader }) => {
  const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";

  return (dispatch) => {
    isMainLoader && dispatch(mainLoadingTrue());
    return fileDownloadService
      .get(url)
      .then((response) => {
        isMainLoader && dispatch(mainLoadingFalse());
        return response.data;
      })
      .catch((err) => {
        console.error("Error:", err);
        isMainLoader && dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectFileMove_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    priorityID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileMove(data));
        } else {
          dispatch(getProjectFileMove({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileMove([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectFileMove = (data) => {
  return {
    type: PROJECT_FILE_MOVE,
    payload: data
  };
};

export const getProjectFileMoveTO_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    priorityID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectFileMoveTO(data));
        } else {
          dispatch(getProjectFileMoveTO({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectFileMoveTO([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectFileMoveTO = (data) => {
  return {
    type: TO_PROJECT_MOVE_FILE,
    payload: data
  };
};

export const UpdateProjectFileMove_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROJECT_FILE_MOVE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateIdentificationOfRevenueCorrection_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskRemark: "",
    subTaskPlanID: [],
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_IDENTIFICATION_OF_REVENUE_CORRECTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdProjectSubTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_SUB_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectSubTaskSuccess(data));
        } else {
          dispatch(getBdProjectSubTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectSubTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectSubTaskSuccess = (data) => {
  return {
    type: GET_PROJECT_SUB_TASK,
    payload: data
  };
};

export const getBdProjectSubTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_SUB_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdProjectSubTaskExportSuccess(data));
        } else {
          dispatch(getBdProjectSubTaskExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdProjectSubTaskExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdProjectSubTaskExportSuccess = (data) => {
  return {
    type: GET_PROJECT_SUB_TASK_EXPORT,
    payload: data
  };
};

export const createBdProjectSubTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROJECT_SUB_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdProjectSubTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROJECT_SUB_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    taskType: "",
    remark: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLiaisonProcessPlanKpi_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_KPI,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanKpiSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanKpiSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanKpiSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanKpiSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_KPI,
    payload: data
  };
};

//export

export const getLiaisonProcessPlanKpiExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_KPI,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanKpiExportSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanKpiExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanKpiExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanKpiExportSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_KPI_EXPORT,
    payload: data
  };
};

export const updateCivilTaskPlanApproval_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanApprovalID: "",
    statusID: "",
    rejectionReasonID: "",
    remark: "",
    ...data
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_TASK_PLAN_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getLiaisonProcessPlanExportData_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    bdProjectTaskID: "",
    processAccountableID: "",
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    completionStatus: "",
    applicabilityStatus: "",
    processOwnerID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_LIAISON_PROCESS_PLAN_EXPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPlanExportDataSuccess(data));
        } else {
          dispatch(getLiaisonProcessPlanExportDataSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPlanExportDataSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPlanExportDataSuccess = (data) => {
  return {
    type: GET_LIAISON_PROCESS_PLAN_EXPORT_DATA,
    payload: data
  };
};

export const getProjectDocumentRepositoryFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentRepositoryFilterProjectSuccess(data));
        } else {
          dispatch(getProjectDocumentRepositoryFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentRepositoryFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentRepositoryFilterProjectSuccess = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT,
    payload: data
  };
};

export const getProjectBdKPIReportFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_BD_KPI_REPORT_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectBdKPIReportFilterProjectSuccess(data));
        } else {
          dispatch(getProjectBdKPIReportFilterProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBdKPIReportFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectBdKPIReportFilterProjectSuccess = (data) => {
  return {
    type: GET_PROJECT_BD_KPI_REPORT_FILTER_PROJECT,
    payload: data
  };
};

export const getProcessFeeItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROCESS_FEE_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProcessFeeItemSuccess(data));
        } else {
          dispatch(getProcessFeeItemSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProcessFeeItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProcessFeeItemSuccess = (data) => {
  return {
    type: GET_PROCESS_FEE_ITEM,
    payload: data
  };
};

export const createProcessFeeItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_PROCESS_FEE_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getBdFeesItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_FEES_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdFeesItemSuccess(data));
        } else {
          dispatch(getBdFeesItemSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdFeesItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdFeesItemSuccess = (data) => {
  return {
    type: GET_BD_FEES_ITEM,
    payload: data
  };
};

export const updateProcessFeeItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_PROCESS_FEE_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectTaskPlanPending_action = (data) => {
  const initialPayload = {
    taskCategoryID: "",
    projectID: "",
    taskID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    civilOwnerID: "",
    projectStatus: "",
    parentID: "0",
    ownershipID: "KNS",
    generalSearch: "",
    sortOrder: "",
    taskOwnerID: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_TASK_PLAN_PENDING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectTaskPlanPendingSuccess(data));
        } else {
          dispatch(getProjectTaskPlanPendingSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectTaskPlanPendingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectTaskPlanPendingSuccess = (data) => {
  return {
    type: GET_PROJECT_TASK_PLAN_PENDING,
    payload: data
  };
};

export const getLiaisonProcessPending_action = (data) => {
  const initialPayload = {
    bdProjectTaskID: "",
    processAccountableID: "",
    processPlanID: "",
    projectID: "",
    projectFileID: "",
    processID: "",
    acquisitionStatus: 1,

    applicabilityStatus: "Applicable",
    processOwnerID: "",
    projectFileStatus: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PENDING_LIAISON_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getLiaisonProcessPendingSuccess(data));
        } else {
          dispatch(getLiaisonProcessPendingSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getLiaisonProcessPendingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getLiaisonProcessPendingSuccess = (data) => {
  return {
    type: GET_PENDING_LIAISON_PROCESS_PLAN,
    payload: data
  };
};

export const createBdFeesItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    itemName: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_BD_FEES_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdFeesItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    feesItemID: "",
    itemName: "",
    status: "",

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_BD_FEES_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

//Land and Site Reg
export const getBdRegistrationPaymentProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentProcessSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentProcessSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentProcessSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_PROCESS,
    payload: data
  };
};

export const getBdRegistrationPaymentDeedType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_DEED_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentDeedTypeSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentDeedTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentDeedTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentDeedTypeSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_DEED_TYPE,
    payload: data
  };
};

export const getBdRegistrationPaymentSurveyInfo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_REGISTRATION_PAYMENT_SURVEY_INFO,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentSurveyInfoSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentSurveyInfoSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentSurveyInfoSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentSurveyInfoSuccess = (data) => {
  return {
    type: GET_REGISTRATION_PAYMENT_SURVEY_INFO,
    payload: data
  };
};

export const getBdRegistrationPaymentSiteInfo_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_SITE_INFO,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentSiteInfoSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentSiteInfoSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentSiteInfoSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentSiteInfoSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_SITE_INFO,
    payload: data
  };
};

export const getBdRegistrationPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_REGISTRATION_PAYMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentSuccess = (data) => {
  return {
    type: GET_REGISTRATION_PAYMENT,
    payload: data
  };
};

export const getBdRegistrationPaymentDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentDetailSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentDetailSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_DETAIL,
    payload: data
  };
};

export const getBdRegistrationPaymentFilterProjectLand_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_LAND,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentFilterProjectLandSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentFilterProjectLandSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentFilterProjectLandSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentFilterProjectLandSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_LAND,
    payload: data
  };
};

export const getBdRegistrationPaymentFilterProjectSite_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    registrationType: "Site",
    paymentType: "",
    projectID: "",
    processID: "",
    companyID: "",
    paymentStatus: "",
    iDisplayStart: 0,
    iDisplayLength: -1,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_SITE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentFilterProjectSiteSuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentFilterProjectSiteSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentFilterProjectSiteSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentFilterProjectSiteSuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_FILTER_PROJECT_SITE,
    payload: data
  };
};

export const getBdRegistrationPaymentFilterCompany_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_REGISTRATION_PAYMENT_FILTER_COMPANY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdRegistrationPaymentFilterCompanySuccess(data));
        } else {
          dispatch(getBdRegistrationPaymentFilterCompanySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdRegistrationPaymentFilterCompanySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdRegistrationPaymentFilterCompanySuccess = (data) => {
  return {
    type: GET_BD_REGISTRATION_PAYMENT_FILTER_COMPANY,
    payload: data
  };
};

export const getCompanyProjectMapRegistration_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    mapStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.COMPANY_PROJECT_MAP, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCompanyProjectMapRegistrationSuccess(data));
        } else {
          dispatch(getCompanyProjectMapRegistrationSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCompanyProjectMapRegistrationSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCompanyProjectMapRegistrationSuccess = (data) => {
  return {
    type: GET_COMPANY_PROJECT_MAP_SITE_REGISTRATION,
    payload: data
  };
};

export const getModeCategorySiteRegistration_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    categoryStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PAYMENT_MODE_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getModeCategoryRegistrationSuccess(data));
        } else {
          dispatch(getModeCategoryRegistrationSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getModeCategoryRegistrationSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getModeCategoryRegistrationSuccess = (data) => {
  return {
    type: GET_MODE_CATEGORY_SITE_REGISTRATION,
    payload: data
  };
};

export const getModeSiteRegistration_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    modeStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PAYMENT_MODE, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getModeRegistrationSuccess(data));
        } else {
          dispatch(getModeRegistrationSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getModeRegistrationSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getModeRegistrationSuccess = (data) => {
  return {
    type: GET_MODE_SITE_REGISTRATION,
    payload: data
  };
};

export const getBdSiteRegistrationProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GET_CRM_PROJECTS, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdSiteRegistrationProjectSuccess(data));
        } else {
          dispatch(getBdSiteRegistrationProjectSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdSiteRegistrationProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdSiteRegistrationProjectSuccess = (data) => {
  return {
    type: GET_SITE_REGISTRATION_PROJECTS,
    payload: data
  };
};

export const createBdRegistrationPayment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_BD_REGISTRATION_PAYMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const deleteBdRegistrationPaymentItem_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    paymentItemID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_BD_REGISTRATION_PAYMENT_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const DeleteBdRegistrationPayment_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_BD_REGISTRATION_PAYMENT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdRegistrationPaymentStatus_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_REGISTRATION_PAYMENT_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdRegistrationPaymentIssueActualAmount_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_REGISTRATION_PAYMENT_ITEM_ACTUAL_AMOUNT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdRegistrationPaymentSettlementSettle_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_REGISTRATION_PAYMENT_SETTLEMENT_SETTLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdRegistrationPaymentSettlementApproval_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_REGISTRATION_PAYMENT_SETTLEMENT_APPROVAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateBdRegistrationPaymentIssue_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_BD_REGISTRATION_PAYMENT_ISSUE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const AddBdRegistrationPaymentItem_action = (data) => {
  let initialPayload = {
    userID: LoginuserId,
    paymentItemID: "",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.ADD_BD_REGISTRATION_PAYMENT_SITE_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success"
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

//Master Process Map

export const getBdFeesItemAvailable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_BD_FEES_ITEM_AVAILABLE, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdFeesItemAvailableSuccess(data));
        } else {
          dispatch(getBdFeesItemAvailableSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdFeesItemAvailableSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdFeesItemAvailableSuccess = (data) => {
  return {
    type: GET_BD_FEES_ITEM_AVAILABLE,
    payload: data
  };
};

export const getBdLandDisputeFilterAccountable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    issueCategoryID: "",
    adminView: "",
    reportView: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_BD_LAND_DISPUTE_FILTER_ACCOUNTABLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getBdLandDisputeFilterAccountableSuccess(data));
        } else {
          dispatch(getBdLandDisputeFilterAccountableSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getBdLandDisputeFilterAccountableSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getBdLandDisputeFilterAccountableSuccess = (data) => {
  return {
    type: GET_BD_LAND_DISPUTE_FILTER_ACCOUNTABLE,
    payload: data
  };
};
export const getCourtCaseFilterAccountable_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    adminView: "",
    reportView: "",

    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_COURT_CASE_FILTER_ACCOUNTABLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCourtCaseFilterAccountableSuccess(data));
        } else {
          dispatch(getCourtCaseFilterAccountableSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCourtCaseFilterAccountableSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCourtCaseFilterAccountableSuccess = (data) => {
  return {
    type: GET_COURT_CASE_FILTER_ACCOUNTABLE,
    payload: data
  };
};

export const getCompanyProjectMapExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    mapStatus: "",
    projectStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.COMPANY_PROJECT_MAP_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: GET_COMPANY_PROJECT_MAP_EXPORT,
            payload: data
          });
        } else {
          dispatch({
            type: GET_COMPANY_PROJECT_MAP_EXPORT,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: GET_COMPANY_PROJECT_MAP_EXPORT,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};

//Project Map

export const getCompanyProjectMapList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    mapStatus: "",
    generalSearch: "",

    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.COMPANY_PROJECT_MAP_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch({
            type: COMPANY_PROJECT_MAP_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: COMPANY_PROJECT_MAP_LIST,
            payload: []
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch({
          type: COMPANY_PROJECT_MAP_LIST,
          payload: []
        });
        dispatch(mainLoadingFalse());
      });
  };
};
