import moment from "moment";

export const dateFormat = (date, dateFormatType = null) => {
  if (!dateFormatType) {
    dateFormatType = "DD-MM-YYYY";
  }
  date = moment(date);
  if (date.isValid()) {
    return moment(date).format(dateFormatType);
  } else {
    return "";
  }
};

export const dateWithoutFormat = (date, dateFormatType = null) => {
  if (!dateFormatType) {
    dateFormatType = "MM-DD-YYYY";
  }
  date = moment(date);
  if (date.isValid()) {
    return moment(date).format(dateFormatType);
  } else {
    return "";
  }
};

export const dateFormatWithTime = (date, dateFormatType = null) => {
  if (!dateFormatType) {
    dateFormatType = "DD-MM-yyyy hh:mm:ss a";
  }
  date = moment(date);
  if (date.isValid()) {
    return moment(date).format(dateFormatType);
  } else {
    return "";
  }
};

export const findDayDifference = (date1, date2) => {
  const momentDate1 = moment(date1, "YYYY-MM-DD HH:mm:ss");
  const momentDate2 = moment(date2, "YYYY-MM-DD HH:mm:ss");

  const dayDifference = momentDate1.diff(momentDate2, "days");

  return dayDifference;
};

export const getMonthYearFormat = (date) => {
  date = moment(date);
  if (date.isValid()) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    const formattedDate = `${monthNames[date.month()]} ${date.year()}`;

    return formattedDate;
  } else {
    return "";
  }
};

// Example usage
const date1 = "2023-08-25 11:30:23";
const currentDate = moment().format("YYYY-MM-DD HH:mm:ss"); // current date and time

const differenceInDays = findDayDifference(date1, currentDate);
// console.log(`Day difference: ${differenceInDays} days`);

// export const dateTimeFormat = (date, dateFormatType=null) =>{
//     if(!dateFormatType){
//         dateFormatType = 'dd-MM-yyyy hh:mm a'
//     }
//     return format(date, dateFormatType);
// }

// export const timeFormat = (time, timeFormatType=null) =>{
//     console.log("=========", time)
//     if(!timeFormatType){
//         timeFormatType = 'HH:MM a'
//     }
//     return format(time, timeFormatType);
// }

// export const timeFormatFromDate = (date = null, timeFormatType=null) =>{
//     if(!timeFormatType){
//         timeFormatType = 'hh:MM'
//     }
//     if(date){
//         date = new Date()
//     }
//     return format(date, timeFormatType);
// }

export const getTimeInAmPm = (time) => {
  let formattedTime = "";
  if (time !== "") {
    formattedTime = moment(time, "HH:mm:ss").format("h:mm A");
  }
  return formattedTime;
};

export const getMinToHours = (totalDurationInMin) => {
  if (totalDurationInMin !== "") {
    const totalDuration = Math.round(parseFloat(totalDurationInMin));
    const duration = moment.duration(totalDuration, "minutes");
    const hours = duration?._data?.hours;
    const mins = duration?._data?.minutes;
    // const minutesRemainder = totalDuration - hours * 60;
    return `${hours} Hr ${mins} Min`;
  } else {
    return "";
  }
};

export const getTimeDiffenceInMin = (actual_start_time, actual_end_time) => {
  const startTime = moment(actual_start_time, "HH:mm:ss");
  const endTime = moment(actual_end_time, "HH:mm:ss");

  let diffInMinutes;
  if (endTime.isBefore(startTime)) {
    diffInMinutes = endTime.diff(startTime.subtract(1, "day"), "minutes");
  } else {
    diffInMinutes = endTime.diff(startTime, "minutes");
  }

  // const diffInHours = Math.abs(diffInMinutes) / 60;
  return diffInMinutes;
};
