import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./styles.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
/**
 *
 * @param {open} true or false value for hide and show
 * @param {text} content text to show
 * @param {type} enum values { error, warning, info, success}
 * @returns
 */
const MAlert = ({ open, alertText }) => {
  return (
    // <Stack sx={{ width: '100%' }} spacing={2}>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={2000}
      onClose={() => {}}
      message="Note archived"
      action={() => {}}
    >
      <Alert severity={alertText.type}>{alertText.text}</Alert>
    </Snackbar>
  );
};

export default MAlert;
