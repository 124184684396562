export const GET_PROJECT_AUTHORITY = "GET_PROJECT_AUTHORITY";
export const GET_BD_USER = "GET_BD_USER";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const ADD_TASK = "ADD_TASK";
export const GET_TASK = "GET_TASK";
export const GET_TASK_CATEGORY = "GET_TASK_CATEGORY";
export const GET_TASK_EXPORT = "GET_TASK_EXPORT";
export const GET_FEASIBILITY_REPORT_DETAIL = "GET_FEASIBILITY_REPORT_DETAIL";
export const UPDATE_TASK_OWNER = "UPDATE_TASK_OWNER";
export const UPDATE_TASK_START = "UPDATE_TASK_START";
export const UPDATE_TASK_COMPLETED = "UPDATE_TASK_COMPLETED";
export const GET_TASK_DETAIL = "GET_TASK_DETAIL";
export const GET_TASK_PROJECT_FILTER = "GET_TASK_PROJECT_FILTER";
export const GET_TASK_CATEGORY_FILTER = "GET_TASK_CATEGORY_FILTER";
export const GET_TASK_DEPARTMENT_FILTER = "GET_TASK_DEPARTMENT_FILTER";
export const GET_TASK_OWNER_FILTER = "GET_TASK_OWNER_FILTER";
export const GET_USER_DESIGNATION = "GET_USER_DESIGNATION";
export const GET_USER_ROLE_FUNCTIONALITY = "GET_USER_ROLE_FUNCTIONALITY";
export const UPDATE_USER_ROLE_FUNCTIONALITY = "UPDATE_USER_ROLE_FUNCTIONALITY";
export const CREATE_USER_ROLE_FUNCTIONALITY = "CREATE_USER_ROLE_FUNCTIONALITY";
export const GET_PUBLIC_HOLIDAY_LIST = "GET_PUBLIC_HOLIDAY_LIST";
export const GET_YEAR = "GET_YEAR";
export const GET_SALARY_ADVANCE = "GET_SALARY_ADVANCE";
export const GET_SALARY_ADVANCE_DEDUCTION_HISTORY =
  "GET_SALARY_ADVANCE_DEDUCTION_HISTORY";
export const GET_SALARY_ADVANCE_EXPORT = "GET_SALARY_ADVANCE_EXPORT";
export const GET_OUT_PASS = "GET_OUT_PASS";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_OUT_PASS_EXPORT = "GET_OUT_PASS_EXPORT";
export const GET_EMPLOYEE_ATTENDANCE = "GET_EMPLOYEE_ATTENDANCE";
export const GET_LEAVE_TYPE = "GET_LEAVE_TYPE";
export const GET_EMPLOYEE_CHECKIN = "GET_EMPLOYEE_CHECKIN";
export const GET_PETTY_CASH = "GET_PETTY_CASH";
export const GET_PETTY_CASH_MASTER_EXPORT = "GET_PETTY_CASH_MASTER_EXPORT";
export const GET_LEAVE_SUMMARY = "GET_LEAVE_SUMMARY";
export const GET_COMP_OFF_LEAVE_SUMMARY = "GET_COMP_OFF_LEAVE_SUMMARY";
export const GET_VEHICLE_SERVICE_REQUEST = "GET_VEHICLE_SERVICE_REQUEST";
export const GET_VECHICLE_TYPE = "GET_VECHICLE_TYPE";
export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE";
export const GET_EMPLOYEE_ATTENDANCE_EXPORT = "GET_EMPLOYEE_ATTENDANCE_EXPORT";
export const GET_VEHICLE_SERVICE_REQUEST_EXPORT =
  "GET_VEHICLE_SERVICE_REQUEST_EXPORT";
export const GET_VEHICLE_NUMBER = "GET_VEHICLE_NUMBER";
export const CREATE_VEHICLE_SERVICE_REQUEST = "CREATE_VEHICLE_SERVICE_REQUEST";
export const GET_GST = "GET_GST";
export const GET_ASSEST_TYPE = "GET_ASSEST_TYPE";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const GET_DESIGNATION_EXPORT = "GET_DESIGNATION_EXPORT";
export const GET_DEPARTMENT_MASTER = "GET_DEPARTMENT_MASTER";
export const GET_DEPARTMENT_EXPORT = "GET_DEPARTMENT_EXPORT";
export const GET_LEVEL = "GET_LEVEL";
export const GET_LEVEL_EXPORT = "GET_LEVEL_EXPORT";
export const GET_JOB_ROLE = "GET_JOB_ROLE";
export const GET_DEPARTMENT_DESIGNATION = "GET_DEPARTMENT_DESIGNATION";
export const GET_TASK_ACCOUNTABLE_FILTER = "GET_TASK_ACCOUNTABLE_FILTER";
export const GET_APPRAISAL_QUESTION = "GET_APPRAISAL_QUESTION";
export const GET_APPRAISAL_QUESTION_EXPORT = "GET_APPRAISAL_QUESTION_EXPORT";
export const GET_APPRAISAL_QUESTION_OPTION = "GET_APPRAISAL_QUESTION_OPTION";
export const GET_APPRAISAL_QUESTION_OPTION_EXPORT =
  "GET_APPRAISAL_QUESTION_OPTION_EXPORT";
export const GET_EMPLOYEE_APPRAISAL_QUESTION =
  "GET_EMPLOYEE_APPRAISAL_QUESTION";
export const GET_EMPLOYEE_APPRAISAL = "GET_EMPLOYEE_APPRAISAL";
export const GET_EMPLOYEE_APPRAISAL_EXPORT = "GET_EMPLOYEE_APPRAISAL_EXPORT";
export const GET_APPRAISAL = "GET_APPRAISAL";
export const GET_APPRAISAL_CATEGORY = "GET_APPRAISAL_CATEGORY";
export const GET_APPRAISAL_RATING = "GET_APPRAISAL_RATING";
export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_VALUE = "GET_QUESTION_VALUE";
export const GET_QUESTION_VALUE_SET = "GET_QUESTION_VALUE_SET";
export const GET_HR_LEAVE_TYPE = "GET_HR_LEAVE_TYPE";
export const GET_APPRAISAL_QUESTION_PRINT = "GET_APPRAISAL_QUESTION_PRINT";
export const GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT =
  "GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT";
export const GET_TO_DO_CATEGORY = "GET_TO_DO_CATEGORY";
export const GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE =
  "GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE";
export const GET_HR_APPRAISAL_QUESTION_DESIGNATION =
  "GET_HR_APPRAISAL_QUESTION_DESIGNATION";
export const GET_APPRAISAL_RATING_EXPORT = "GET_APPRAISAL_RATING_EXPORT";
export const GET_APPRAISAL_QUESTION_CATEGORY_FILTER =
  "GET_APPRAISAL_QUESTION_CATEGORY_FILTER";
export const GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER =
  "GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER";
export const GET_APPRAISAL_QUESTION_DESIGNATION_FILTER =
  "GET_APPRAISAL_QUESTION_DESIGNATION_FILTER";
export const GET_HR_APPRAISAL_QUESTION_OPTION =
  "GET_HR_APPRAISAL_QUESTION_OPTION";
export const GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL =
  "GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL";
export const GET_EMPLOYEE_FOR_APPRAISAL = "GET_EMPLOYEE_FOR_APPRAISAL";
export const GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE =
  "GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE";
export const GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL =
  "GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL";
export const GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL =
  "GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL";
export const GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT =
  "GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT";
export const GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION =
  "GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION";
export const GET_EMPLOYEE_APPRAISAL_DETAIL = "GET_EMPLOYEE_APPRAISAL_DETAIL";
export const GET_PENDING_TODO = "GET_PENDING_TODO";
export const GET_USER_ROLE_FUNCTIONALITY_DETAIL =
  "GET_USER_ROLE_FUNCTIONALITY_DETAIL";
export const GET_USER_ROLE_FUNCTIONALITY_FILTER_DEPARTMENT =
  "GET_USER_ROLE_FUNCTIONALITY_FILTER_DEPARTMENT";
export const GET_USER_ROLE_FUNCTIONALITY_FILTER_DESIGNATION =
  "GET_USER_ROLE_FUNCTIONALITY_FILTER_DESIGNATION";
//Hr Module User Bata
export const GET_USER_BATA = "GET_USER_BATA";
export const GET_USER_BATA_FILTER_USER = "GET_USER_BATA_FILTER_USER";
export const GET_USER_BATA_EXPORT = "GET_USER_BATA_EXPORT";
